
export const ImageNameDisplay = ({ imageName }) => {

  // Extract the first 8 and last 8 letters from the image name
  const first8 = imageName.slice(0, 8);
  const last8 = imageName.slice(-8);

  return `${first8}.....${last8}`
}

