import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useEffect, useState } from "react";
ChartJS.register(
  CategoryScale,
  LineElement,
  PointElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ChartComponent = ({
  year,
  generatorData,
  companyVehicle,
  boilerData,
  lpg,
  refrigerants,
  fuelwood,
  metered,
  waste,
  food,
  freight,
  safari,
}) => {
  const [data, setdata] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const mergedData = {};

  useEffect(() => {
    const monthlyTotals = [
      generatorData || [],
      companyVehicle || [],
      boilerData || [],
      lpg || [],
      refrigerants || [],
      fuelwood || [],
      metered || [],
      waste || [],
      food || [],
      freight || [],
      safari || [],
    ];

    monthlyTotals.forEach((array) => {
      array.forEach((obj) => {
        const { month, emissions } = obj;

        if (!mergedData[month]) {
          mergedData[month] = {
            emissions: 0,
          };
        }

        if (emissions !== "" && !isNaN(parseFloat(emissions))) {
          mergedData[month].emissions += parseFloat(emissions);
        }
      });
    });

    setdata({
      labels,
      datasets: [
        {
          label: "emissions",
          data: Object.values(mergedData).map((item) =>
            Number(item.emissions || 0)
          ),
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    });
  }, [
    year,
    generatorData,
    companyVehicle,
    boilerData,
    lpg,
    refrigerants,
    fuelwood,
    metered,
    waste,
    food,
    freight,
    safari,
  ]);

  return (
    <div className="mt-2 dashboard col-lg-12 col-md-12 col-sm-12">
      <div className="d-flex justify-content-between align-items-center row">
        <h1 className="dashbord-title">Total Emission</h1>
      </div>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center w-100">
          Loading..
        </div>
      )}
      {data && !isLoading && <Line options={options} data={data} />}
    </div>
  );
};

export default ChartComponent;
