import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import Form from "react-bootstrap/Form";
import {
  getBoilerFurnaceReportByYear,
  getFoodAndBeverageReportByYear,
  getFreightReportByYear,
  getFuelwoodCoalReportByYear,
  getGeneratorReportByYear,
  getLPGReportByYear,
  getCompanyvehicleReportByYear,
  getMeteredElectricityReportByYear,
  getSolarReportByYear,
  getRefrigerantReportByYear,
  getSafariTaxiReportByYear,
  getWasteReportByYear,
} from "./Utils/api";
import Loader from "./components/Loader";

const EmissionReport = () => {
  const [year, setyear] = useState("2022");
  const [scope1Data, setScope1Data] = useState({});
  const [scope2Data, setScope2Data] = useState({});
  const [scope3Data, setScope3Data] = useState({});
  const [reductionData, setReductionData] = useState({});
  const [loader, setLoader] = useState(false);

  const onChangeHandler = (e) => {
    setTimeout(() => {
      setyear(e?.target?.value);
    }, 1000);
  };

  const getData = async () => {
    setLoader(true);
    try {
      const scope1Promises = [
        getGeneratorReportByYear(year),
        getBoilerFurnaceReportByYear(year),
        getLPGReportByYear(year),
        getCompanyvehicleReportByYear(year),
        getRefrigerantReportByYear(year),
        getFuelwoodCoalReportByYear(year),
      ];

      const scope2Promises = [getMeteredElectricityReportByYear(year)];

      const scope3Promises = [
        getWasteReportByYear(year),
        getFoodAndBeverageReportByYear(year),
        getFreightReportByYear(year),
        getSafariTaxiReportByYear(year),
      ];

      const scopeReductionPromises = [getSolarReportByYear(year)];

      const [
        scope1Results,
        scope2Results,
        scope3Results,
        scopeReductionResults,
      ] = await Promise.all([
        Promise.all(scope1Promises),
        Promise.all(scope2Promises),
        Promise.all(scope3Promises),
        Promise.all(scopeReductionPromises),
      ]);

      const [
        generatorData,
        boilerData,
        lpg,
        companyVehicle,
        refrigerants,
        fuelwood,
      ] = scope1Results;
      setScope1Data({
        generatorData: generatorData?.data,
        boilerData: boilerData?.data,
        lpg: lpg?.data,
        companyVehicle: companyVehicle?.data,
        refrigerants: refrigerants?.data,
        fuelwood: fuelwood?.data,
      });

      const [metered] = scope2Results;
      setScope2Data({
        metered: metered?.data,
      });

      const [solar] = scopeReductionResults;
      setReductionData({
        solar: solar?.data,
      });

      const [waste, food, freight, safari] = scope3Results;
      setScope3Data({
        waste: waste?.data,
        food: food?.data,
        freight: freight?.data,
        safari: safari?.data,
      });
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [year]);

  // Select the emission value for the specified year and field
  const emissionBoilerFurnace = scope1Data?.boilerData
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);

  const emissionDieselConsumption = scope1Data?.generatorData
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);
  const emissionCompanyVehicle = scope1Data?.companyVehicle
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);
  const emissionLPG = scope1Data?.lpg
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);
  const emissionFuelwoodCoal = scope1Data?.fuelwood
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);
  const emissionRefrigerants = scope1Data?.refrigerants
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);
  const emissionElectricity = scope2Data?.metered
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);
  const emissionWaste = scope3Data?.waste
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);
  const emissionFoodandBev = scope3Data?.food
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);
  const emissionFreight = scope3Data?.freight
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);
  const emissionSafaritaxi = scope3Data?.safari
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);
  const emissionSolar = reductionData?.solar
    ?.reduce((acc, curr) => acc + (curr?.emissions || 0), 0)
    ?.toFixed(3);

  const totalStationaryScope1 = (
    Number(emissionBoilerFurnace || 0) +
    Number(emissionDieselConsumption || 0) +
    Number(emissionLPG || 0) +
    Number(emissionFuelwoodCoal || 0)
  ).toFixed(3);

  const totalMobileScope1 = Number(emissionCompanyVehicle || 0).toFixed(3);

  const totalFugitiveScope1 = Number(emissionRefrigerants || 0).toFixed(3);

  const totalEmissionsValueScope1 = (
    Number(emissionBoilerFurnace || 0) +
    Number(emissionDieselConsumption || 0) +
    Number(emissionCompanyVehicle || 0) +
    Number(emissionLPG || 0) +
    Number(emissionFuelwoodCoal || 0) +
    Number(emissionRefrigerants || 0)
  ).toFixed(3);

  const totalEmissionsValueScope2 = Number(emissionElectricity || 0).toFixed(3);
  const totalReduction = Number(emissionSolar || 0).toFixed(3);

  const totalEmissionsValueScope3 = (
    Number(emissionWaste || 0) +
    Number(emissionFoodandBev || 0) +
    Number(emissionFreight || 0) +
    Number(emissionSafaritaxi || 0)
  ).toFixed(3);

  const totalEmissions = (
    Number(totalEmissionsValueScope1) +
    Number(totalEmissionsValueScope2) +
    Number(totalEmissionsValueScope3)
  ).toFixed(3);

  const totalReductions = Number(totalReduction).toFixed(3);

  const rows = [
    {
      scope: "Scope 1",
      source: "Generator (DG)",
      value: emissionDieselConsumption,
      netPercentageOfTotalEmission:
        (emissionDieselConsumption / totalEmissions) * 100,
    },
    {
      scope: "Scope 1",
      source: "Boiler & Furnance ",
      value: emissionBoilerFurnace,
      netPercentageOfTotalEmission:
        (emissionBoilerFurnace / totalEmissions) * 100,
    },
    {
      scope: "Scope 1",
      source: "LPG",
      value: emissionLPG,
      netPercentageOfTotalEmission: (emissionLPG / totalEmissions) * 100,
    },
    {
      scope: "Scope 1",
      source: "Fuelwood/Coal",
      value: emissionFuelwoodCoal,
      netPercentageOfTotalEmission:
        (emissionFuelwoodCoal / totalEmissions) * 100,
    },
    {
      scope: "Scope 1",
      source: "Company Vehicle",
      value: emissionCompanyVehicle,
      netPercentageOfTotalEmission:
        (emissionCompanyVehicle / totalEmissions) * 100,
    },
    {
      scope: "Scope 1",
      source: "Refrigerants",
      value: emissionRefrigerants,
      netPercentageOfTotalEmission:
        (emissionRefrigerants / totalEmissions) * 100,
    },
    {
      scope: "Scope 2",
      source: "Metered Electricity",
      value: emissionElectricity,
      netPercentageOfTotalEmission:
        (emissionElectricity / totalEmissions) * 100,
    },
    {
      scope: "Scope 3",
      source: "Waste Disposal",
      value: emissionWaste,
      netPercentageOfTotalEmission: (emissionWaste / totalEmissions) * 100,
    },
    {
      scope: "Scope 3",
      source: "Food & Beverage",
      value: emissionFoodandBev,
      netPercentageOfTotalEmission: (emissionFoodandBev / totalEmissions) * 100,
    },
    {
      scope: "Scope 3",
      source: "Freight",
      value: emissionFreight,
      netPercentageOfTotalEmission: (emissionFreight / totalEmissions) * 100,
    },
    {
      scope: "Scope 3",
      source: "Safaris",
      value: emissionSafaritaxi,
      netPercentageOfTotalEmission: (emissionSafaritaxi / totalEmissions) * 100,
    },
    {
      scope: "Reduction",
      source: "Solar",
      value: emissionSolar,
      netPercentageOfTotalEmission: (emissionSolar / totalReductions) * 100,
    },
  ];

  const downloadPDF = () => {
    const element = document.getElementById("report-container");

    if (element) {
      const opt = {
        margin: 10,
        filename: "emissions_report.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf().set(opt).from(element).save();
    }
  };

  return (
    <Box sx={{ width: "80%", mx: "auto", mt: 3 }}>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="col-lg-10 col-md-10 col-sm-9 col-xs-12">
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            className="title-main"
          >
            Emissions Report
          </Typography>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <Form.Select
            aria-label="Default select example"
            className="form-select-main"
            onChange={onChangeHandler}
          >
            <option value="2021" selected={year === "2021"}>
              2021
            </option>
            <option value="2022" selected={year === "2022"}>
              2022
            </option>
            <option value="2023" selected={year === "2023"}>
              2023
            </option>
            <option value="2024" selected={year === "2024"}>
              2024
            </option>
          </Form.Select>
        </div>
      </div>
      <div>
        <div className="pb-2">
          <button
            className="excelbutton"
            onClick={() => {
              downloadPDF();
            }}
          >
            Download Emission Report
          </button>
        </div>
        {loader && <Loader />}
        {!loader && (
          <div id="report-container" className="inner-card-main">
            <TableContainer component={Paper}>
              <Table
                style={{ borderCollapse: "separate", borderSpacing: "0 0" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Emission Source</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Net GHG Emission (tCO₂e)</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Net % Of Total Emission</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <strong>Scope 1</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <strong>Stationary Combustion</strong>
                    </TableCell>
                  </TableRow>
                  {rows.slice(0, 4).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.source}</TableCell>
                      <TableCell align="right">
                        {row.value !== null &&
                        row.value !== undefined &&
                        row.value !== 0
                          ? row.value
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        {!isNaN(row.netPercentageOfTotalEmission) &&
                        row.netPercentageOfTotalEmission !== null &&
                        row.netPercentageOfTotalEmission !== undefined
                          ? `${row.netPercentageOfTotalEmission.toFixed(3)}%`
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <strong>{totalStationaryScope1}</strong>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <strong>Mobile Combustion</strong>
                    </TableCell>
                  </TableRow>
                  {rows.slice(4, 5).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.source}</TableCell>
                      <TableCell align="right">
                        {row.value !== null &&
                        row.value !== undefined &&
                        row.value !== 0
                          ? row.value
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        {!isNaN(row.netPercentageOfTotalEmission) &&
                        row.netPercentageOfTotalEmission !== null &&
                        row.netPercentageOfTotalEmission !== undefined
                          ? `${row.netPercentageOfTotalEmission.toFixed(3)}%`
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <strong>{totalMobileScope1}</strong>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <strong>Fugitive Emissions</strong>
                    </TableCell>
                  </TableRow>
                  {rows.slice(5, 6).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.source}</TableCell>
                      <TableCell align="right">
                        {row.value !== null &&
                        row.value !== undefined &&
                        row.value !== 0
                          ? row.value
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        {!isNaN(row.netPercentageOfTotalEmission) &&
                        row.netPercentageOfTotalEmission !== null &&
                        row.netPercentageOfTotalEmission !== undefined
                          ? `${row.netPercentageOfTotalEmission.toFixed(3)}%`
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <strong>{totalFugitiveScope1}</strong>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <strong>{totalEmissionsValueScope1}</strong>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <strong>Scope 2</strong>
                    </TableCell>
                  </TableRow>
                  {rows.slice(6, 7).map((row, index) => (
                    <TableRow key={index + 5}>
                      <TableCell>{row.source}</TableCell>
                      <TableCell align="right">
                        {row.value !== null &&
                        row.value !== undefined &&
                        row.value !== 0
                          ? row.value
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        {!isNaN(row.netPercentageOfTotalEmission) &&
                        row.netPercentageOfTotalEmission !== null &&
                        row.netPercentageOfTotalEmission !== undefined
                          ? `${row.netPercentageOfTotalEmission.toFixed(3)}%`
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <strong>{totalEmissionsValueScope2}</strong>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <strong>Scope 3</strong>
                    </TableCell>
                  </TableRow>
                  {rows.slice(7, 11).map((row, index) => (
                    <TableRow key={index + 6}>
                      <TableCell>{row.source}</TableCell>
                      <TableCell align="right">
                        {row.value !== null &&
                        row.value !== undefined &&
                        row.value !== 0
                          ? row.value
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        {!isNaN(row.netPercentageOfTotalEmission) &&
                        row.netPercentageOfTotalEmission !== null &&
                        row.netPercentageOfTotalEmission !== undefined
                          ? `${row.netPercentageOfTotalEmission.toFixed(3)}%`
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <strong>{totalEmissionsValueScope3}</strong>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <strong>Reductions</strong>
                    </TableCell>
                  </TableRow>
                  {rows.slice(11).map((row, index) => (
                    <TableRow key={index + 11}>
                      <TableCell>{row.source}</TableCell>
                      <TableCell align="right">
                        {row.value !== null &&
                        row.value !== undefined &&
                        row.value !== 0
                          ? row.value
                          : ""}
                      </TableCell>
                      <TableCell align="right">
                        {!isNaN(row.netPercentageOfTotalEmission) &&
                        row.netPercentageOfTotalEmission !== null &&
                        row.netPercentageOfTotalEmission !== undefined
                          ? `${row.netPercentageOfTotalEmission.toFixed(3)}%`
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <strong>{totalReduction}</strong>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {/* <TableRow>
                  <TableCell>
                    <strong>Total GHG Emission</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totalEmissions}</strong>
                  </TableCell>
                  <TableCell align="right">
                  <strong>100%</strong>
                  </TableCell>
                </TableRow> */}
                </TableBody>
              </Table>

              <Table
                style={{
                  marginTop: "15px",
                  borderCollapse: "separate",
                  borderSpacing: "0 0",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <strong>Total GHG Emission (tCO2e)</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Scope 1</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Scope 2</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Scope 3</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      {totalEmissionsValueScope1}
                    </TableCell>
                    <TableCell align="center">
                      {totalEmissionsValueScope2}
                    </TableCell>
                    <TableCell align="center">
                      {totalEmissionsValueScope3}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      <strong>Total</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>{totalEmissions} </strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      <strong>Reduction</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>- {totalReduction}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      <strong>Grand Total</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>
                        {Number(totalEmissions) >= Number(totalReduction)
                          ? `- ${Number(
                              totalEmissions - totalReduction
                            ).toFixed(3)}`
                          : `+ ${Number(
                              totalReduction - totalEmissions
                            ).toFixed(3)}`}
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </Box>
  );
};

export default EmissionReport;
