import React from 'react';

const XLSXViewer = ({ src }) => {
  return (
    <div>
      <iframe title="XLSX Viewer" src={src} width="100%" height="600" frameBorder="0"></iframe>
    </div>
  );
};

export default XLSXViewer;
