import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import ExcelJS from "exceljs";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { setEmission } from "../../EmissionSlice";
import {
  generateRefrigerant,
  getRefrigerantReportByYear,
} from "../../Utils/api";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { uniqBy } from 'lodash'

const refrigerants = {
  "R-12": 10900,
  "R-22": 1810,
  "HFC-23": 11700,
  "HFC-32": 650,
  "HFC-125": 2800,
  "HFC-134a": 1300,
  "HFC-143a": 3800,
  "HFC-152a": 140,
  "HFC-236fa": 6300,
  "R-290": 3,
  "R-401A": 18,
  "R-401B": 15,
  "R-401C": 21,
  "R-402A": 1680,
  "R-402B": 1064,
  "R-403A": 1400,
  "R-403B": 2730,
  "R-404A": 3260,
  "R-406A": 0,
  "R-407A": 1770,
  "R-407B": 2285,
  "R-407C": 1526,
  "R-407D": 1428,
  "R-407E": 1363,
  "R-408A": 1944,
  "R-409A": 0,
  "R-409B": 0,
  "R-410A": 1725,
  "R-410B": 1833,
  "R-411A": 15,
  "R-412A": 4,
  "R-411B": 350,
  "R-413A": 1774,
  "R-414A": 0,
  "R-414B": 0,
  "R-415A": 25,
  "R-415B": 105,
  "R-416A": 767,
  "R-417A": 1955,
  "R-418A": 4,
  "R-419A": 2403,
  "R-420A": 1144,
  "R-500": 37,
  "R-501": 0,
  "R-502": 0,
  "R-503": 4692,
  "R-504": 313,
  "R-505": 0,
  "R-506": 0,
  "R-507 or R-507A": 3300,
  "R-508A": 10175,
  "R-508B": 10350,
  "R-509 or R-509A": 3920,
  "R-600a": 3,
  "R-1270": 2,
  "R-744": 1,
  "R-717": 0,
  "PFC-218 (C3F8)": 7000,
  "PFC-116 (C2F6)": 9200,
  "PFC-14 (CF4)": 6500,
};

const options = [
  { name: "R-12", key: "r_12", value: 10900 },
  { name: "R-22", key: "r_22", value: 1810 },
  { name: "HFC-23", key: "hfc_23", value: 11700 },
  { name: "HFC-32", key: "hfc_32", value: 650 },
  { name: "HFC-125", key: "hfc_125", value: 2800 },
  { name: "HFC-134a", key: "hfc_134a", value: 1300 },
  { name: "HFC-143a", key: "hfc_143a", value: 3800 },
  { name: "HFC-152a", key: "hfc_152a", value: 140 },
  { name: "HFC-236fa", key: "hfc_236fa", value: 6300 },
  { name: "R-290", key: "r_290", value: 3 },
  { name: "R-401A", key: "r_401a", value: 18 },
  { name: "R-401B", key: "r_401b", value: 15 },
  { name: "R-401C", key: "r_401c", value: 21 },
  { name: "R-402A", key: "r_402a", value: 1680 },
  { name: "R-402B", key: "r_402b", value: 1064 },
  { name: "R-403A", key: "r_403a", value: 1400 },
  { name: "R-403B", key: "r_403b", value: 2730 },
  { name: "R-404A", key: "r_404a", value: 3260 },
  { name: "R-406A", key: "r_406a", value: 0 },
  { name: "R-407A", key: "r_407a", value: 1770 },
  { name: "R-407B", key: "r_407b", value: 2285 },
  { name: "R-407C", key: "r_407c", value: 1526 },
  { name: "R-407D", key: "r_407d", value: 1428 },
  { name: "R-407E", key: "r_407e", value: 1363 },
  { name: "R-408A", key: "r_408a", value: 1944 },
  { name: "R-409A", key: "r_409a", value: 0 },
  { name: "R-409B", key: "r_409b", value: 0 },
  { name: "R-410A", key: "r_410a", value: 1725 },
  { name: "R-410B", key: "r_410b", value: 1833 },
  { name: "R-411A", key: "r_411a", value: 15 },
  { name: "R-412A", key: "r_412a", value: 4 },
  { name: "R-411B", key: "r_411b", value: 350 },
  { name: "R-413A", key: "r_413a", value: 1774 },
  { name: "R-414A", key: "r_414a", value: 0 },
  { name: "R-414B", key: "r_414b", value: 0 },
  { name: "R-415A", key: "r_415a", value: 25 },
  { name: "R-416A", key: "r_416a", value: 767 },
  { name: "R-417A", key: "r_417a", value: 1955 },
  { name: "R-418A", key: "r_418a", value: 4 },
  { name: "R-419A", key: "r_419a", value: 2403 },
  { name: "R-420A", key: "r_420a", value: 1144 },
  { name: "R-500", key: "r_500", value: 37 },
  { name: "R-501", key: "r_501", value: 0 },
  { name: "R-502", key: "r_502", value: 0 },
  { name: "R-503", key: "r_503", value: 4692 },
  { name: "R-504", key: "r_504", value: 313 },
  { name: "R-505", key: "r_505", value: 0 },
  { name: "R-506", key: "r_506", value: 0 },
  { name: "R-507 or R-507A", key: "r_507", value: 3300 },
  { name: "R-508A", key: "r_508a", value: 10175 },
  { name: "R-508B", key: "r_508b", value: 10350 },
  { name: "R-509 or R-509A", key: "r_509", value: 3920 },
  { name: "R-600a", key: "r_600a", value: 3 },
  { name: "R-1270", key: "r_1270", value: 2 },
  { name: "R-744", key: "r_744", value: 1 },
  { name: "R-717", key: "r_717", value: 0 },
  { name: "PFC-218 (C3F8)", key: "pfc_218", value: 7000 },
  { name: "PFC-116 (C2F6)", key: "pfc_116", value: 9200 },
  { name: "PFC-14 (CF4)", key: "pfc_14", value: 6500 },
];

function Refrigerants() {
  const dispatch = useDispatch();
  const [year, setYear] = useState(2022);
  const [excelData, setExcelData] = useState([]);
  const [showEmissions, setShowEmissions] = useState(false);
  const [totalIndex, setTotalIndex] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loader, setloader] = useState(true);

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const calculateTotalEmissions = (data) => {
    const totalEmissionsValue = data
      .reduce((total, item) => total + (Number(item?.emissions) || 0), 0)
      .toFixed(3);
    return totalEmissionsValue;
  };

  useEffect(() => {
    if(selectedOptions?.length > 0){
      localStorage.setItem("selectedOptionsRefrigerants", JSON.stringify(selectedOptions));
    }

    if(year){
      localStorage.setItem("selectedYear", JSON.stringify(year));
    }
  }, [selectedOptions, year]);

  useEffect(() => {
    const storedOptions = JSON.parse(localStorage.getItem("selectedOptionsRefrigerants"));
    const storedYear = JSON.parse(localStorage.getItem("selectedYear"));

    if (storedOptions) {
      setSelectedOptions(storedOptions);
    }

    if (storedYear) {
      setYear(storedYear);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [year]);

  const getData = async () => {
    setloader(true);
    const { data } = await getRefrigerantReportByYear(year);
    let formattedData = JSON.parse(
      JSON.stringify(
        data?.map((i) => ({
          ...i,
          month: `${
            months.find((month) => month?.value == i?.month)?.name
          } ${year}`,
          month_num: i.month,
          "R-12": i?.["r_12"],
          "R-22": i?.["r_22"],
          "HFC-23": i?.["hfc_23"],
          "HFC-32": i?.["hfc_32"],
          "HFC-125": i?.["hfc_125"],
          "HFC-134a": i?.["hfc_134a"],
          "HFC-143a": i?.["hfc_143a"],
          "HFC-152a": i?.["hfc_152a"],
          "HFC-236fa": i?.["hfc_236fa"],
          "R-290": i?.["r_290"],
          "R-401A": i?.["r_401a"],
          "R-401B": i?.["r_401b"],
          "R-401C": i?.["r_401c"],
          "R-402A": i?.["r_402a"],
          "R-402B": i?.["r_402b"],
          "R-403A": i?.["r_403a"],
          "R-403B": i?.["r_403b"],
          "R-404A": i?.["r_404a"],
          "R-406A": i?.["r_406a"],
          "R-407A": i?.["r_407a"],
          "R-407B": i?.["r_407b"],
          "R-407C": i?.["r_407c"],
          "R-407D": i?.["r_407d"],
          "R-407E": i?.["r_407e"],
          "R-408A": i?.["r_408a"],
          "R-409A": i?.["r_409a"],
          "R-409B": i?.["r_409b"],
          "R-410A": i?.["r_410a"],
          "R-410B": i?.["r_410b"],
          "R-411A": i?.["r_411a"],
          "R-412A": i?.["r_412a"],
          "R-411B": i?.["r_411b"],
          "R-413A": i?.["r_413a"],
          "R-414A": i?.["r_414a"],
          "R-414B": i?.["r_414b"],
          "R-415A": i?.["r_415a"],
          "R-416A": i?.["r_416a"],
          "R-417A": i?.["r_417a"],
          "R-418A": i?.["r_418a"],
          "R-419A": i?.["r_419a"],
          "R-420A": i?.["r_420a"],
          "R-500": i?.["r_500"],
          "R-501": i?.["r_501"],
          "R-502": i?.["r_502"],
          "R-503": i?.["r_503"],
          "R-504": i?.["r_504"],
          "R-505": i?.["r_505"],
          "R-506": i?.["r_506"],
          "R-507 or R-507A": i?.["r_507"],
          "R-508A": i?.["r_508a"],
          "R-508B": i?.["r_508b"],
          "R-509 or R-509A": i?.["r_509"],
          "R-600a": i?.["r_600a"],
          "R-1270": i?.["r_1270"],
          "R-744": i?.["r_744"],
          "R-717": i?.["r_717"],
          "PFC-218 (C3F8)": i?.["pfc_218"],
          "PFC-116 (C2F6)": i?.["pfc_116"],
          "PFC-14 (CF4)": i?.["pfc_14"],
        }))
      )
    );

    let updatedExcelData = formattedData;

    if (updatedExcelData?.length < 12) {
      await handleGenerateExcel(false, false);
      // After generating data, fetch again if needed
      const updatedData = await getRefrigerantReportByYear(year);
      updatedExcelData = JSON.parse(
        JSON.stringify(
          updatedData?.data?.map((i) => ({
            ...i,
            month: `${
              months.find((month) => month?.value == i?.month)?.name
            } ${year}`,
            month_num: i.month,
            "R-12": i?.["r_12"],
            "R-22": i?.["r_22"],
            "HFC-23": i?.["hfc_23"],
            "HFC-32": i?.["hfc_32"],
            "HFC-125": i?.["hfc_125"],
            "HFC-134a": i?.["hfc_134a"],
            "HFC-143a": i?.["hfc_143a"],
            "HFC-152a": i?.["hfc_152a"],
            "HFC-236fa": i?.["hfc_236fa"],
            "R-290": i?.["r_290"],
            "R-401A": i?.["r_401a"],
            "R-401B": i?.["r_401b"],
            "R-401C": i?.["r_401c"],
            "R-402A": i?.["r_402a"],
            "R-402B": i?.["r_402b"],
            "R-403A": i?.["r_403a"],
            "R-403B": i?.["r_403b"],
            "R-404A": i?.["r_404a"],
            "R-406A": i?.["r_406a"],
            "R-407A": i?.["r_407a"],
            "R-407B": i?.["r_407b"],
            "R-407C": i?.["r_407c"],
            "R-407D": i?.["r_407d"],
            "R-407E": i?.["r_407e"],
            "R-408A": i?.["r_408a"],
            "R-409A": i?.["r_409a"],
            "R-409B": i?.["r_409b"],
            "R-410A": i?.["r_410a"],
            "R-410B": i?.["r_410b"],
            "R-411A": i?.["r_411a"],
            "R-412A": i?.["r_412a"],
            "R-411B": i?.["r_411b"],
            "R-413A": i?.["r_413a"],
            "R-414A": i?.["r_414a"],
            "R-414B": i?.["r_414b"],
            "R-415A": i?.["r_415a"],
            "R-416A": i?.["r_416a"],
            "R-417A": i?.["r_417a"],
            "R-418A": i?.["r_418a"],
            "R-419A": i?.["r_419a"],
            "R-420A": i?.["r_420a"],
            "R-500": i?.["r_500"],
            "R-501": i?.["r_501"],
            "R-502": i?.["r_502"],
            "R-503": i?.["r_503"],
            "R-504": i?.["r_504"],
            "R-505": i?.["r_505"],
            "R-506": i?.["r_506"],
            "R-507 or R-507A": i?.["r_507"],
            "R-508A": i?.["r_508a"],
            "R-508B": i?.["r_508b"],
            "R-509 or R-509A": i?.["r_509"],
            "R-600a": i?.["r_600a"],
            "R-1270": i?.["r_1270"],
            "R-744": i?.["r_744"],
            "R-717": i?.["r_717"],
            "PFC-218 (C3F8)": i?.["pfc_218"],
            "PFC-116 (C2F6)": i?.["pfc_116"],
            "PFC-14 (CF4)": i?.["pfc_14"],
          }))
        )
      );
    } else {
      if (!updatedExcelData.some((item) => item.month === "Total")) {
        const totalRow = {
          month: "Total",
          emissions: calculateTotalEmissions(updatedExcelData),
        };
        updatedExcelData.push(totalRow);
        setTotalIndex(updatedExcelData.length - 1);
      }
    }

    setExcelData(updatedExcelData);
    setloader(false);
  };

  const processData = (data) => {
    let processedData = data.map((item) => {
      let totalEmissions = 0;
      for (const [gas, gwp] of Object.entries(refrigerants)) {
        let gasEmissions = (item[gas] || 0) * gwp;
        totalEmissions += gasEmissions / 1000.0;
        item[gas + "Emissions"] = gasEmissions;
      }
      return {
        ...item,
        totalEmissions,
      };
    });

    // Calculate total emissions
    let totalEmissionsValue = processedData
      .reduce((total, item) => total + item.totalEmissions, 0)
      .toFixed(3);

    // Update the total emissions in the Redux store
    handleEmissionChange(year, "emissionRefrigerants", totalEmissionsValue);

    return processedData;
  };

  const handleEmissionChange = (year, field, value) => {
    dispatch(setEmission({ year, field, value }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      ...selectedOptions.map((option) => ({
        Header: `Net ${option.value} used in kg`,
        accessor: option.value,
      })),
      {
        Header: "Total Emissions (tCO₂e)",
        accessor: "emissions",
        show: showEmissions,
      },
    ],
    [selectedOptions, showEmissions]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: excelData });

  const handleChange = (rowIndex, columnId, value) => {
    if (selectedOptions.find((option) => option.value === columnId)) {
      setExcelData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        })
      );
    }
  };

  const handleGenerateExcel = async (showMsg = true, loadDefault = true) => {
    var newExceldata = excelData;
    if (excelData?.length === 0) {
      const object = options.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: null }),
        {}
      );
      const Data = months.map((month) => ({
        month: `${month?.name} ${year}`,
        month_num: month?.value,
        ...object,
      }));
      newExceldata = Data;
    }
    try {
      const formData = {
        emissionsData: newExceldata
          ?.filter((i) => i?.month_num > 0)
          ?.map((i) => ({
            year,
            month: i?.month_num,
            r_12: i?.["R-12"] === "0" || i?.["R-12"] === 0 ? 0 : i?.["R-12"] === null ? null : Number(i?.["R-12"]),
            r_22: i?.["R-22"] === "0" || i?.["R-22"] === 0 ? 0 : i?.["R-22"] === null ? null : Number(i?.["R-22"]),
            hfc_23: i?.["HFC-23"] === "0" || i?.["HFC-23"] === 0 ? 0 : i?.["HFC-23"] === null ? null : Number(i?.["HFC-23"]),
            hfc_32: i?.["HFC-32"] === "0" || i?.["HFC-32"] === 0 ? 0 : i?.["HFC-32"] === null ? null : Number(i?.["HFC-32"]),
            hfc_125: i?.["HFC-125"] === "0" || i?.["HFC-125"] === 0 ? 0 : i?.["HFC-125"] === null ? null : Number(i?.["HFC-125"]),
            hfc_134a: i?.["HFC-134a"] === "0" || i?.["HFC-134a"] === 0 ? 0 : i?.["HFC-134a"] === null ? null : Number(i?.["HFC-134a"]),
            hfc_143a: i?.["HFC-143a"] === "0" || i?.["HFC-143a"] === 0 ? 0 : i?.["HFC-143a"] === null ? null : Number(i?.["HFC-143a"]),
            hfc_152a: i?.["HFC-152a"] === "0" || i?.["HFC-152a"] === 0 ? 0 : i?.["HFC-152a"] === null ? null : Number(i?.["HFC-152a"]),
            hfc_236fa: i?.["HFC-236fa"] === "0" || i?.["HFC-236fa"] === 0 ? 0 : i?.["HFC-236fa"] === null ? null : Number(i?.["HFC-236fa"]),
            r_290: i?.["R-290"] === "0" || i?.["R-290"] === 0 ? 0 : i?.["R-290"] === null ? null : Number(i?.["R-290"]),
            r_401a: i?.["R-401A"] === "0" || i?.["R-401A"] === 0 ? 0 : i?.["R-401A"] === null ? null : Number(i?.["R-401A"]),
            r_401b: i?.["R-401B"] === "0" || i?.["R-401B"] === 0 ? 0 : i?.["R-401B"] === null ? null : Number(i?.["R-401B"]),
            r_401c: i?.["R-401C"] === "0" || i?.["R-401C"] === 0 ? 0 : i?.["R-401C"] === null ? null : Number(i?.["R-401C"]),
            r_402a: i?.["R-402A"] === "0" || i?.["R-402A"] === 0 ? 0 : i?.["R-402A"] === null ? null : Number(i?.["R-402A"]),
            r_402b: i?.["R-402B"] === "0" || i?.["R-402B"] === 0 ? 0 : i?.["R-402B"] === null ? null : Number(i?.["R-402B"]),
            r_403a: i?.["R-403A"] === "0" || i?.["R-403A"] === 0 ? 0 : i?.["R-403A"] === null ? null : Number(i?.["R-403A"]),
            r_403b: i?.["R-403B"] === "0" || i?.["R-403B"] === 0 ? 0 : i?.["R-403B"] === null ? null : Number(i?.["R-403B"]),
            r_404a: i?.["R-404A"] === "0" || i?.["R-404A"] === 0 ? 0 : i?.["R-404A"] === null ? null : Number(i?.["R-404A"]),
            r_406a: i?.["R-406A"] === "0" || i?.["R-406A"] === 0 ? 0 : i?.["R-406A"] === null ? null : Number(i?.["R-406A"]),
            r_407a: i?.["R-407A"] === "0" || i?.["R-407A"] === 0 ? 0 : i?.["R-407A"] === null ? null : Number(i?.["R-407A"]),
            r_407b: i?.["R-407B"] === "0" || i?.["R-407B"] === 0 ? 0 : i?.["R-407B"] === null ? null : Number(i?.["R-407B"]),
            r_407c: i?.["R-407C"] === "0" || i?.["R-407C"] === 0 ? 0 : i?.["R-407C"] === null ? null : Number(i?.["R-407C"]),
            r_407d: i?.["R-407D"] === "0" || i?.["R-407D"] === 0 ? 0 : i?.["R-407D"] === null ? null : Number(i?.["R-407D"]),
            r_407e: i?.["R-407E"] === "0" || i?.["R-407E"] === 0 ? 0 : i?.["R-407E"] === null ? null : Number(i?.["R-407E"]),
            r_408a: i?.["R-408A"] === "0" || i?.["R-408A"] === 0 ? 0 : i?.["R-408A"] === null ? null : Number(i?.["R-408A"]),
            r_409a: i?.["R-409A"] === "0" || i?.["R-409A"] === 0 ? 0 : i?.["R-409A"] === null ? null : Number(i?.["R-409A"]),
            r_409b: i?.["R-409B"] === "0" || i?.["R-409B"] === 0 ? 0 : i?.["R-409B"] === null ? null : Number(i?.["R-409B"]),
            r_410a: i?.["R-410A"] === "0" || i?.["R-410A"] === 0 ? 0 : i?.["R-410A"] === null ? null : Number(i?.["R-410A"]),
            r_410b: i?.["R-410B"] === "0" || i?.["R-410B"] === 0 ? 0 : i?.["R-410B"] === null ? null : Number(i?.["R-410B"]),
            r_411a: i?.["R-411A"] === "0" || i?.["R-411A"] === 0 ? 0 : i?.["R-411A"] === null ? null : Number(i?.["R-411A"]),
            r_412a: i?.["R-412A"] === "0" || i?.["R-412A"] === 0 ? 0 : i?.["R-412A"] === null ? null : Number(i?.["R-412A"]),
            r_411b: i?.["R-411B"] === "0" || i?.["R-411B"] === 0 ? 0 : i?.["R-411B"] === null ? null : Number(i?.["R-411B"]),
            r_413a: i?.["R-413A"] === "0" || i?.["R-413A"] === 0 ? 0 : i?.["R-413A"] === null ? null : Number(i?.["R-413A"]),
            r_414a: i?.["R-414A"] === "0" || i?.["R-414A"] === 0 ? 0 : i?.["R-414A"] === null ? null : Number(i?.["R-414A"]),
            r_414b: i?.["R-414B"] === "0" || i?.["R-414B"] === 0 ? 0 : i?.["R-414B"] === null ? null : Number(i?.["R-414B"]),
            r_415a: i?.["R-415A"] === "0" || i?.["R-415A"] === 0 ? 0 : i?.["R-415A"] === null ? null : Number(i?.["R-415A"]),
            r_416a: i?.["R-416A"] === "0" || i?.["R-416A"] === 0 ? 0 : i?.["R-416A"] === null ? null : Number(i?.["R-416A"]),
            r_417a: i?.["R-417A"] === "0" || i?.["R-417A"] === 0 ? 0 : i?.["R-417A"] === null ? null : Number(i?.["R-417A"]),
            r_418a: i?.["R-418A"] === "0" || i?.["R-418A"] === 0 ? 0 : i?.["R-418A"] === null ? null : Number(i?.["R-418A"]),
            r_419a: i?.["R-419A"] === "0" || i?.["R-419A"] === 0 ? 0 : i?.["R-419A"] === null ? null : Number(i?.["R-419A"]),
            r_420a: i?.["R-420A"] === "0" || i?.["R-420A"] === 0 ? 0 : i?.["R-420A"] === null ? null : Number(i?.["R-420A"]),
            r_500: i?.["R-500"] === "0" || i?.["R-500"] === 0 ? 0 : i?.["R-500"] === null ? null : Number(i?.["R-500"]),
            r_501: i?.["R-501"] === "0" || i?.["R-501"] === 0 ? 0 : i?.["R-501"] === null ? null : Number(i?.["R-501"]),
            r_502: i?.["R-502"] === "0" || i?.["R-502"] === 0 ? 0 : i?.["R-502"] === null ? null : Number(i?.["R-502"]),
            r_503: i?.["R-503"] === "0" || i?.["R-503"] === 0 ? 0 : i?.["R-503"] === null ? null : Number(i?.["R-503"]),
            r_504: i?.["R-504"] === "0" || i?.["R-504"] === 0 ? 0 : i?.["R-504"] === null ? null : Number(i?.["R-504"]),
            r_505: i?.["R-505"] === "0" || i?.["R-505"] === 0 ? 0 : i?.["R-505"] === null ? null : Number(i?.["R-505"]),
            r_506: i?.["R-506"] === "0" || i?.["R-506"] === 0 ? 0 : i?.["R-506"] === null ? null : Number(i?.["R-506"]),
            r_507: i?.["R-507 or R-507A"] === "0" || i?.["R-507 or R-507A"] === 0 ? 0 : i?.["R-507 or R-507A"] === null ? null : Number(i?.["R-507 or R-507A"]),
            r_508a: i?.["R-508A"] === "0" || i?.["R-508A"] === 0 ? 0 : i?.["R-508A"] === null ? null : Number(i?.["R-508A"]),
            r_508b: i?.["R-508B"] === "0" || i?.["R-508B"] === 0 ? 0 : i?.["R-508B"] === null ? null : Number(i?.["R-508B"]),
            r_509: i?.["R-509 or R-509A"] === "0" || i?.["R-509 or R-509A"] === 0 ? 0 : i?.["R-509 or R-509A"] === null ? null : Number(i?.["R-509 or R-509A"]),
            r_600a: i?.["R-600a"] === "0" || i?.["R-600a"] === 0 ? 0 : i?.["R-600a"] === null ? null : Number(i?.["R-600a"]),
            r_1270: i?.["R-1270"] === "0" || i?.["R-1270"] === 0 ? 0 : i?.["R-1270"] === null ? null : Number(i?.["R-1270"]),
            r_744: i?.["R-744"] === "0" || i?.["R-744"] === 0 ? 0 : i?.["R-744"] === null ? null : Number(i?.["R-744"]),
            r_717: i?.["R-717"] === "0" || i?.["R-717"] === 0 ? 0 : i?.["R-717"] === null ? null : Number(i?.["R-717"]),
            pfc_218: i?.["PFC-218 (C3F8)"] === "0" || i?.["PFC-218 (C3F8)"] === 0 ? 0 : i?.["PFC-218 (C3F8)"] === null ? null : Number(i?.["PFC-218 (C3F8)"]),
            pfc_116: i?.["PFC-116 (C2F6)"] === "0" || i?.["PFC-116 (C2F6)"] === 0 ? 0 : i?.["PFC-116 (C2F6)"] === null ? null : Number(i?.["PFC-116 (C2F6)"]),
            pfc_14: i?.["PFC-14 (CF4)"] === "0" || i?.["PFC-14 (CF4)"] === 0 ? 0 : i?.["PFC-14 (CF4)"] === null ? null : Number(i?.["PFC-14 (CF4)"]),
          })),
      };

      const { data } = await generateRefrigerant(formData);

      let processedExcelData = processData(JSON.parse(JSON.stringify(data)));

      if (loadDefault) {
        await getData();
      }

      let totalEmissionsValue = processedExcelData
        .reduce((total, item) => total + item.emissions, 0)
        .toFixed(3);

      processedExcelData = processedExcelData.map((item) => {
        const formattedMonth = months.find((i) => i.value == item.month);
        return {
          ...item,
          month_num: item?.month,
          month: `${formattedMonth?.name} ${year}`,
          "R-12": item?.["r_12"],
          "R-22": item?.["r_22"],
          "HFC-23": item?.["hfc_23"],
          "HFC-32": item?.["hfc_32"],
          "HFC-125": item?.["hfc_125"],
          "HFC-134a": item?.["hfc_134a"],
          "HFC-143a": item?.["hfc_143a"],
          "HFC-152a": item?.["hfc_152a"],
          "HFC-236fa": item?.["hfc_236fa"],
          "R-290": item?.["r_290"],
          "R-401A": item?.["r_401a"],
          "R-401B": item?.["r_401b"],
          "R-401C": item?.["r_401c"],
          "R-402A": item?.["r_402a"],
          "R-402B": item?.["r_402b"],
          "R-403A": item?.["r_403a"],
          "R-403B": item?.["r_403b"],
          "R-404A": item?.["r_404a"],
          "R-406A": item?.["r_406a"],
          "R-407A": item?.["r_407a"],
          "R-407B": item?.["r_407b"],
          "R-407C": item?.["r_407c"],
          "R-407D": item?.["r_407d"],
          "R-407E": item?.["r_407e"],
          "R-408A": item?.["r_408a"],
          "R-409A": item?.["r_409a"],
          "R-409B": item?.["r_409b"],
          "R-410A": item?.["r_410a"],
          "R-410B": item?.["r_410b"],
          "R-411A": item?.["r_411a"],
          "R-412A": item?.["r_412a"],
          "R-411B": item?.["r_411b"],
          "R-413A": item?.["r_413a"],
          "R-414A": item?.["r_414a"],
          "R-414B": item?.["r_414b"],
          "R-415A": item?.["r_415a"],
          "R-416A": item?.["r_416a"],
          "R-417A": item?.["r_417a"],
          "R-418A": item?.["r_418a"],
          "R-419A": item?.["r_419a"],
          "R-420A": item?.["r_420a"],
          "R-500": item?.["r_500"],
          "R-501": item?.["r_501"],
          "R-502": item?.["r_502"],
          "R-503": item?.["r_503"],
          "R-504": item?.["r_504"],
          "R-505": item?.["r_505"],
          "R-506": item?.["r_506"],
          "R-507 or R-507A": item?.["r_507"],
          "R-508A": item?.["r_508a"],
          "R-508B": item?.["r_508b"],
          "R-509 or R-509A": item?.["r_509"],
          "R-600a": item?.["r_600a"],
          "R-1270": item?.["r_1270"],
          "R-744": item?.["r_744"],
          "R-717": item?.["r_717"],
          "PFC-218 (C3F8)": item?.["pfc_218"],
          "PFC-116 (C2F6)": item?.["pfc_116"],
          "PFC-14 (CF4)": item?.["pfc_14"],
          emissions: item.emissions !== null ? item.emissions.toFixed(3) : "",
        };
      });

      setShowEmissions(true);

      if (totalIndex !== null) {
        processedExcelData[totalIndex] = {
          month: "Total",
          emissions: totalEmissionsValue,
        };
      } else {
        processedExcelData.push({
          month: "Total",
          emissions: totalEmissionsValue,
        });
        setTotalIndex(processedExcelData.length - 1);
      }

      setExcelData(processedExcelData);

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Gas Consumption");

      let selectedRefrigerants = selectedOptions.map((option) => option.value);
      worksheet.columns = [
        { header: "Month", key: "month", width: 15 },
        ...selectedRefrigerants.map((gas) => ({
          header: `Net ${gas} used in kg`,
          key: gas,
          width: 25,
        })),
        { header: "Total Emissions (tCO₂e)", key: "emissions", width: 25 },
      ];

      processedExcelData.forEach((item) => {
        worksheet.addRow(item);
      });

      worksheet.addRow({ month: "Total", totalEmissions: totalEmissionsValue });

      if (showMsg) {
        toast.success("Data generated!!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const updateExcel = (currentYear) => {
    const newExcelData = months.map((month) => ({
      month: `${month?.name} ${currentYear}`,
      month_num: month?.value,
      ...Object.keys(refrigerants).reduce(
        (obj, gas) => ({ ...obj, [gas]: null }),
        {}
      ),
      dieselConsumption: "",
    }));
    setExcelData(newExcelData);
    setShowEmissions(false);
    setTotalIndex(null);
  };

  const selectOptions = Object.keys(refrigerants).map((gas) => ({
    value: gas,
    label: `Net ${gas} used in kg`,
  }));

  const customFilter = (option, rawInput) => {
    const regex = new RegExp(rawInput, "i");
    return regex.test(option.label);
  };

  return (
    <div className="card-main">
      <h5 className="title-main">Refrigerants</h5>
      <div className="d-flex justify-content-center lable-main">
        <div className="pb-3 mx-2">
          <label className=" text-left">
            Year:
            <select
              value={year}
              onChange={(e) => {
                setYear(e.target.value);
                updateExcel(e.target.value);
              }}
              className="form-control select-box"
            >
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </label>
        </div>
        <div>
          <label className="text-left">
            Gases Used:
            <Select
              className="input-width"
              options={selectOptions}
              isMulti
              value={selectedOptions} // Set initial selected options
              onChange={setSelectedOptions}
              filterOption={customFilter}
            />
          </label>
        </div>
      </div>
      <div className="pt-3 text-center">
        <button className="excelbutton" onClick={handleGenerateExcel}>
          Calculate Emissions
        </button>
      </div>
      <div className="mt-5 d-flex justify-content-center">
        {loader ? (
          <Loader />
        ) : (
          <table
            className="inner-card-table p-3"
            {...getTableProps()}
            style={{ margin: "auto" }}
          >
            <thead className="col-md-10 col-sm-8">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="table-title" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="col-md-10 col-sm-8" {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {((cell?.column?.Header === "Total Emissions (tCO₂e)") || (cell?.column?.Header === "Month")) ? (
                          <div className="table-input p-2 col-md-11 col-sm-11 input-border">
                            {cell.value}
                          </div>
                        ) : (
                          <input
                            type={
                              cell.column.id === "month" ? "text" : "number"
                            }
                            className="table-input p-2 col-md-11 col-sm-11"
                            value={cell.value !== null ? cell.value : null} // Display value or empty string
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (/^\d*\.?\d{0,3}$/.test(inputValue)) {
                                handleChange(i, cell.column.id, inputValue);
                              }
                            }}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default Refrigerants;
