import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import {
  deleteFugitiveEmissions,
  getFugitiveEmissions,
  uploadFugitiveEmissions,
} from "../../Utils/api";
import { Trash } from "react-feather";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ImageNameDisplay } from "../../Utils/helpers";

const UploadDocumentsFug = () => {
  const [documentData, setDocumentData] = useState([]);
  const [loader, setLoader] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [year, setyear] = useState(2022);

  useEffect(() => {
    getData();
  }, [year]);

  const getData = async () => {
    setLoader(true);
    try {
      const { data } = await getFugitiveEmissions(year);
      setDocumentData(data);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
    setLoader(false);
  };

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setIsModalOpen(false);
  };
  const maxFiles = 15;

  const handleFileChange = async (event) => {
    setLoader(true);
    try {
      const newFiles = Array.from(event.target.files);
      const validFiles = newFiles.filter(isFileValid);

      if (validFiles) {
        setSelectedFiles([...selectedFiles, ...validFiles]);
        const formData = new FormData();
        validFiles.forEach((file) => {
          formData.append("files", file);
          console.log(`Uploading file: ${file.name}`);
        });
        formData.append("year", year);

        await uploadFugitiveEmissions(formData);
        await getData();
      } else {
        toast.error(`something went wrong`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
    setLoader(false);
  };

  const onClickDelete = async (id) => {
    setLoader(true);

    try {
      await deleteFugitiveEmissions(id);
      await getData();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
    setLoader(false);
  };

  const isFileValid = (file) => {
    const allowedExtensions = ["pdf", "jpg", "jpeg", "png"];
    const extension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(extension);
  };

  const getStatusBadge = (status) => {
    let variant = "";
    switch (status.toLowerCase()) {
      case "approved":
        variant = "success";
        break;
      case "pending":
        variant = "warning";
        break;
      case "rejected":
        variant = "danger";
        break;
      default:
        variant = "secondary";
    }
    return <Badge bg={variant}>{capitalizeFirstLetter(status)}</Badge>;
  };

  const capitalizeFirstLetter = (str = "") => {
    const words = str?.split(" ");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1);
      }
      return word; // Handle empty words (e.g., multiple spaces)
    });

    // Join the capitalized words back into a string
    return capitalizedWords.join(" ");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Document Name",
        accessor: "document_name",
      },
      {
        Header: "Image",
        accessor: "presigned_url",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: documentData });

  const handleDelete = async (id) => {
    try {
      // Show a confirmation dialog before deleting
      confirmAlert({
        title: "Confirm Deletion",
        message: "Are you sure you want to delete this document?",
        buttons: [
          {
            label: "Yes",
            onClick: () => onClickDelete(id),
          },
          {
            label: "No",
            onClick: () => {
              // Do nothing if the user cancels the deletion
            },
          },
        ],
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const renderTooltip = (name) => (
    <Tooltip id="image-name-tooltip">{name}</Tooltip>
  );

  return (
    <div className="card-main">
      <h5 className="title-main">Documents</h5>
      <div className="pt-3 text-center">
        <div className="mb-2">
          {/* <Alert variant="success">
            There are {documentData.length} document(s) already in this section.{" "}
            <br />
            {remainingSlots > 0 ? (
              <span>
                You can upload {remainingSlots} more file(s) with a maximum of{" "}
                {maxFiles}.
              </span>
            ) : (
              <span>You need to remove some files to upload more.</span>
            )}
          </Alert> */}
        </div>
        <input
          type="file"
          id="fileInput"
          multiple
          style={{ display: "none" }}
          maxFiles={15}
          accept=".pdf, .jpg, .jpeg, .png" // Specify allowed file types
          onChange={handleFileChange}
        />
        <button
          className="excelbutton"
          disabled={loader}
          onClick={() => document.getElementById("fileInput").click()}
        >
          Upload Documents
        </button>
        <label className="pb-3 mx-2 text-left">
          Year:
          <select
            value={year}
            onChange={(e) => {
              setyear(e.target.value);
            }}
            className="form-control select-box"
          >
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </label>
      </div>
      <div className="mt-5">
        {loader ? (
          <>
            <div style={{ margin: "0 auto" }}>
              <Loader />
              <div className="text-center">Please Do not close this Tab...</div>
            </div>
          </>
        ) : (
          <table
            className="inner-card p-3"
            {...getTableProps()}
            style={{ margin: "auto" }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {documentData?.length === 0 ? (
                    <th
                      className="table-title"
                      colSpan={headerGroup.headers?.length}
                    >
                      No Records Found
                    </th>
                  ) : (
                    headerGroup.headers.map((column) => (
                      <th className="table-title" {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))
                  )}
                </tr>
              ))}
            </thead>
            <tbody className="col-md-10 col-sm-8" {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell?.column?.Header === "Image" ? (
                          <>
                            <div className="table-input p-2 col-md-11 col-sm-11 upload-img pointer">
                              {row?.values?.document_name
                                ?.split(".")
                                ?.pop()
                                ?.toLowerCase() === "pdf" ? (
                                <div
                                  onClick={() =>
                                    openModal(
                                      <iframe
                                        src={cell?.value}
                                        width="100%"
                                        height="100%"
                                        style={{ minHeight: "500px" }}
                                      ></iframe>
                                    )
                                  }
                                >
                                  <iframe
                                    title="PDF Viewer"
                                    src={cell?.value}
                                    width="100px"
                                    height="80px"
                                    frameBorder="0"
                                    scrolling="no"
                                  ></iframe>
                                </div>
                              ) : (
                                <img
                                  src={cell?.value}
                                  alt="img"
                                  onClick={() =>
                                    openModal(
                                      <img
                                        src={cell?.value}
                                        alt="Your Image"
                                        style={{
                                          width: "100%",
                                          maxHeight: "500px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    )
                                  }
                                />
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="table-input p-2 col-md-11 col-sm-11 ">
                              {cell?.column?.Header === "Status" &&
                                getStatusBadge(cell.value)}
                              {cell?.column?.Header === "Action" &&
                                row?.values?.status !== "approved" && (
                                  <div
                                    className="pointer"
                                    onClick={() =>
                                      handleDelete(row?.values?.id)
                                    }
                                  >
                                    <Trash color="red" />
                                  </div>
                                )}
                              {cell?.column?.Header === "Document Name" &&
                                <>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={renderTooltip(cell?.value)}
                                ><div>
                                  <ImageNameDisplay imageName={cell?.value} />
                                </div>
                                
                                </OverlayTrigger>
                              </>}
                              {cell?.column?.Header === "Id" && cell.value}
                            </div>
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      <Modal
        size="lg"
        show={isModalOpen}
        onHide={closeModal}
        className="img-viewer"
      >
        <Modal.Header closeButton>View Document</Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          {modalContent}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UploadDocumentsFug;
