import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Generator from "./DashboardChart/Generator";
import TotalMonthGenerator1 from "./DashboardChart/TotalMonthGeneratorScope1";
import TotalMonthGenerator2 from "./DashboardChart/TotalMonthGeneratorScope2";
import TotalMonthGenerator3 from "./DashboardChart/TotalMonthGeneratorScope3";
import JanuaryReport from "./DashboardChart/JanuaryReport";
import CompanyVehicle from "./DashboardChart/CompanyVehicle";
import Boiler from "./DashboardChart/Boiler";
import Lpg from "./DashboardChart/Lpg";
import Refrigerants from "./DashboardChart/Refrigerants";
import Fuelwood from "./DashboardChart/Fuelwood";
import Metered from "./DashboardChart/Metered";
import Waste from "./DashboardChart/Waste";
import Food from "./DashboardChart/Food";
import Freight from "./DashboardChart/Freight";
import Safari from "./DashboardChart/Safari";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import {
  getBoilerFurnaceReportByYear,
  getCompanyvehicleReportByYear,
  getFoodAndBeverageReportByYear,
  getFreightReportByYear,
  getFuelwoodCoalReportByYear,
  getGeneratorReportByYear,
  getLPGReportByYear,
  getMeteredElectricityReportByYear,
  getRefrigerantReportByYear,
  getSafariTaxiReportByYear,
  getWasteReportByYear,
} from "./Utils/api";
import useAuth from "./Utils/hooks/useAuth";

const DashboardReport = () => {
  const [year, setyear] = useState("2022");
  const [activeKey, setActiveKey] = useState("0");
  const [scope1Data, setScope1Data] = useState({});
  const [scope2Data, setScope2Data] = useState({});
  const [scope3Data, setScope3Data] = useState({});
const {user} = useAuth()
  const handleAccordionToggle = (eventKey) => {
    setActiveKey((prevKey) => (prevKey === eventKey ? null : eventKey));
  };

  const onChangeHandler = (e) => {
    setTimeout(() => {
      setyear(e?.target?.value);
    }, 1000);
  };

  const getData = async () => {
    try {
      const scope1Promises = [
        getGeneratorReportByYear(year),
        getBoilerFurnaceReportByYear(year),
        getLPGReportByYear(year),
        getCompanyvehicleReportByYear(year),
        getRefrigerantReportByYear(year),
        getFuelwoodCoalReportByYear(year),
      ];

      const scope2Promises = [getMeteredElectricityReportByYear(year)];

      const scope3Promises = [
        getWasteReportByYear(year),
        getFoodAndBeverageReportByYear(year),
        getFreightReportByYear(year),
        getSafariTaxiReportByYear(year),
      ];

      const [scope1Results, scope2Results, scope3Results] = await Promise.all([
        Promise.all(scope1Promises),
        Promise.all(scope2Promises),
        Promise.all(scope3Promises),
      ]);

      const [
        generatorData,
        boilerData,
        lpg,
        companyVehicle,
        refrigerants,
        fuelwood,
      ] = scope1Results;
      setScope1Data({
        generatorData: generatorData?.data,
        boilerData: boilerData?.data,
        lpg: lpg?.data,
        companyVehicle: companyVehicle?.data?.filter((j) => j?.type === JSON.parse(localStorage.getItem("user"))?.company_vehile_prefrence),
        refrigerants: refrigerants?.data,
        fuelwood: fuelwood?.data,
      });

      const [metered] = scope2Results;
      setScope2Data({
        metered: metered?.data,
      });

      const [waste, food, freight, safari] = scope3Results;
      setScope3Data({
        waste: waste?.data,
        food: food?.data,
        freight: freight?.data,
        safari: safari?.data,
      });
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [year]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="col-lg-10 col-md-10 col-sm-9 col-xs-12">
          <Typography variant="h5" className="title-main pb-0" gutterBottom component="div">
            Dashboard
          </Typography>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-12">
          <Form.Select
            aria-label="Default select example"
            className="form-select-main"
            onChange={onChangeHandler}
          >
            <option value="2021" selected={year === "2021"}>
              2021
            </option>
            <option value="2022" selected={year === "2022"}>
              2022
            </option>
            <option value="2023" selected={year === "2023"}>
              2023
            </option>
            <option value="2024" selected={year === "2024"}>
              2024
            </option>
          </Form.Select>
        </div>
      </div>
      <div className="row">
        <TotalMonthGenerator1 {...scope1Data} year={year} />
        <TotalMonthGenerator2 {...scope2Data} year={year} />
        <TotalMonthGenerator3 {...scope3Data} year={year} />
      </div>
      <div className="row">
        <JanuaryReport
          year={year}
          {...scope1Data}
          {...scope2Data}
          {...scope3Data}
        />
      </div>
      <br></br>
      <br></br>
      <div className="row">
        <Accordion defaultActiveKey="0" onSelect={handleAccordionToggle}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Scope 1</Accordion.Header>
            <Accordion.Body>
              {activeKey === "0" && (
                <div className="row">
                  <Generator year={year} />
                  <CompanyVehicle year={year} />
                  <Boiler year={year} />
                  <Lpg year={year} />
                  <Refrigerants year={year} />
                  <Fuelwood year={year} />
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Scope 2</Accordion.Header>
            <Accordion.Body>
              {activeKey === "1" && (
                <div className="row">
                  <Metered year={year} />
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Scope 3</Accordion.Header>
            <Accordion.Body>
              {activeKey === "2" && (
                <div className="row">
                  <Waste year={year} />
                  <Food year={year} />
                  <Freight year={year} />
                  <Safari year={year} />
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default DashboardReport;
