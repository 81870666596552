import React, { useState, useEffect } from "react";
import "./App.css";
import {
  AppBar,
  Box,
  Collapse,
  Container,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Toolbar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DieselConsumption from "./components/Scope_1/DieselConsumption";
import DashboardReport from "../src/DashboardReport";
import EmissionReport from "../src/EmissionReport";
import CompanyVehicle from "./components/Scope_1/CompanyVehicle";
import BoilerFurnace from "./components/Scope_1/BoilerFurnace";
import LPG from "./components/Scope_1/LPG";
import Refrigerants from "./components/Scope_1/Refrigerants";
import FuelwoodCoal from "./components/Scope_1/FuelwoodCoal";
import MeteredElectricity from "./components/Scope_2/MeteredElectricity";
import Waste from "./components/Scope_3/Waste";
import FoodAndBeverages from "./components/Scope_3/FoodAndBeverages";
import Freight from "./components/Scope_3/Freight";
import SafariTaxi from "./components/Scope_3/SafariTaxi";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Logo from "./assets/images/logo/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "./Utils/hooks/useAuth";
import Solar from "./components/Reduction/Solar";
import ChangePassword from "./components/auth/ChangePassword";
import UploadDocumentsFug from "./components/Scope_1/UploadDocumentsFug";
import UploadDocumentsMob from "./components/Scope_1/UploadDocumentsMob";
import UploadDocumentsSta from "./components/Scope_1/UploadDocumentsSta";
import UploadDocumentsS2 from "./components/Scope_2/UploadDocumentsS2";
import UploadDocumentsS3 from "./components/Scope_3/UploadDocumentsS3";
import UploadCSV from "./components/UploadCSV/UploadCSV"
import classNames from "classnames";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4caf50", // green
    },
    secondary: {
      main: "#ffeb3b", // yellow
    },
  },
});

export default function MainLayout() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const params = location?.search;

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [openScopes, setOpenScopes] = useState(false);
  const [currentPage, setCurrentPage] = useState(params?.split("?")?.[1]);

  const [openScope1, setOpenScope1] = useState(false);
  const [openScope11, setopenScope11] = useState(false);
  const [openScope12, setopenScope12] = useState(false);
  const [openScope13, setopenScope13] = useState(false);

  const [openScope2, setOpenScope2] = useState(false);
  const [openScope3, setOpenScope3] = useState(false);
  const [openReduction, setOpenReduction] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);

  const [openCompanyVehicle, setOpenCompanyVehicle] = useState(false);

  useEffect(() => {
    setCurrentPage(params?.split("?")?.[1]);
  }, [params]);

  const handleScopesClick = () => {
    setOpenScopes(!openScopes);
  };

  const handleScope1Click = () => {
    setOpenScope1(!openScope1);
    setOpenScope2(false);
    setopenScope12(false);
    setopenScope13(false);
    setOpenScope3(false);
    setOpenReduction(false);
    setOpenSetting(false);
  };

  const handleScope2Click = () => {
    setOpenScope2(!openScope2);
    setOpenScope1(false);
    setOpenScope3(false);
    setopenScope11(false);
    setopenScope13(false);
    setOpenReduction(false);
    setOpenSetting(false);
  };

  const handleReductionClick = () => {
    setOpenReduction(!openReduction);
    setOpenScope1(false);
    setOpenScope2(false);
    setOpenScope3(false);
    setopenScope11(false);
    setopenScope12(false);
    setopenScope13(false);
    setOpenSetting(false);
  };

  const handleSettingClick = () => {
    setOpenSetting(!openSetting);
    setOpenScope1(false);
    setOpenScope2(false);
    setopenScope11(false);
    setopenScope12(false);
    setopenScope13(false);
    setOpenScope3(false);
    setOpenReduction(false);
  };

  const handleScope3Click = () => {
    setOpenScope3(!openScope3);
    setOpenScope1(false);
    setopenScope11(false);
    setopenScope12(false);
    setopenScope13(false);
    setOpenScope2(false);
    setOpenSetting(false);
    setOpenReduction(false);
  };

  const handleCompanyVehicleClick = () => {
    setOpenCompanyVehicle(!openCompanyVehicle);
  };

  const handleStationaryCombustionClick = () => {
    setopenScope11(!openScope11);
    setopenScope12(false);
    setopenScope13(false);
    setOpenScope2(false);
    setOpenScope3(false);
    setOpenSetting(false);
    setOpenReduction(false);
  };

  const handleMobileCombustionClick = () => {
    setopenScope12(!openScope12);
    setopenScope11(false);
    setopenScope13(false);
    setOpenScope3(false);
    setOpenSetting(false);
    setOpenReduction(false);
  };

  const handleFugitiveEmissionsClick = () => {
    setopenScope13(!openScope13);
    setopenScope12(false);
    setopenScope11(false);
    setOpenScope2(false);
    setOpenSetting(false);
    setOpenReduction(false);
  };

  const onClickLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() =>{
    setOpenScope3(['Waste','FoodAndBeverages','Freight','SafariTaxi','UploadDocumentsS3']?.includes(currentPage))
    setOpenScope2(['MeteredElectricity','UploadDocumentsS2']?.includes(currentPage))
    setopenScope11(['GeneratorDG','BoilerFurnace','LPG','FuelwoodCoal','UploadDocumentsSta']?.includes(currentPage))
    setopenScope12(['CompanyVehicle','UploadDocumentsMob']?.includes(currentPage))
    setopenScope13(['Refrigerants','UploadDocumentsFug']?.includes(currentPage))
    setOpenScope1(['GeneratorDG','BoilerFurnace','LPG','FuelwoodCoal','UploadDocumentsSta','CompanyVehicle','UploadDocumentsMob','Refrigerants','UploadDocumentsFug']?.includes(currentPage))
  },[currentPage])

  const renderPage = () => {
    switch (currentPage) {
      case "":
        return <DashboardReport />;
      case "Dashboard":
        return <DashboardReport />;
      case "GeneratorDG":
        return <DieselConsumption />;
      case "CompanyVehicle":
        return <CompanyVehicle />;
      case "LPG":
        return <LPG />;
      case "BoilerFurnace":
        return <BoilerFurnace />; // When you have a component for this
      case "Refrigerants":
        return <Refrigerants />;
      case "FuelwoodCoal":
        return <FuelwoodCoal />;
      case "MeteredElectricity":
        return <MeteredElectricity />;
      case "SafariTaxi":
        return <SafariTaxi />;
      case "Waste":
        return <Waste />;
      case "FoodAndBeverages":
        return <FoodAndBeverages />;
      case "Freight":
        return <Freight />;
      case "Solar":
        return <Solar />;
      case "ChangePassword":
        return <ChangePassword />;
      case "EmissionReport":
        return <EmissionReport />;
      case "UploadDocumentsFug":
        return <UploadDocumentsFug />;
      case "UploadDocumentsMob":
        return <UploadDocumentsMob />;
      case "UploadDocumentsSta":
        return <UploadDocumentsSta />;
      case "UploadDocumentsS2":
        return <UploadDocumentsS2 />;
      case "UploadDocumentsS3":
        return <UploadDocumentsS3 />;
      case "UploadCSV" :
        return <UploadCSV />;
      default:
        return <DashboardReport />;
    }
  };

  // return <div>{emissionBoilerFurnace}</div>;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {
        <Box sx={{ display: "flex", width: "100%" }}>
          <AppBar
            position="fixed"
            sx={{
              width: drawerOpen ? "calc(100% - 150px)" : "100%",
              marginLeft: drawerOpen ? "300px" : "0",
              transition: "all 0.3s",
            }}
          >
            <Toolbar className="header-maain">
              <div className="d-flex justify-content-between align-items-center w-100 ">
                <div>
                  <IconButton
                    className="menu-icon"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    <MenuIcon />
                  </IconButton>
                  {/* <div className="d-flex"> */}
                  <span
                    color="inherit"
                    className="menu-icon text-small"
                    style={{ fontSize: "18px" }}
                  >
                    <a>{user?.email}</a>
                  </span>
                </div>
                {/*  <IconButton color="inherit" className='menu-icon'>
                <Settings />
              </IconButton>
              <IconButton color="inherit" className='menu-icon'>
                <SupportAgent />
              </IconButton>
              <IconButton color="inherit" className='menu-icon'>
                <AccountCircle />
              </IconButton> */}
                {/* </div> */}
                <div className="text-white">Welcome, {user?.name}</div>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            className="left-panel"
            variant="persistent"
            open={drawerOpen}
            sx={{ width: drawerOpen ? "100px" : "100px", flexShrink: 0 }}
          >
            <div className="d-flex inner-logo justify-content-center">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                <img src={Logo} alt="Logo" className="logo" />
              </IconButton>
            </div>
            <List component="div" disablePadding>
              <ListItem
                button
                className="list-item-inner"
                onClick={() => navigate("/")}
              >
                <ListItemText primary="Dashboard" />
              </ListItem>
              {/* <ListItem
                button
                className={classNames("list-item-inner", {
                  selected: currentPage === "UploadCSV",
                })}
                onClick={() => navigate("?UploadCSV")}
              >
                <ListItemText primary="Upload CSV" />
              </ListItem> */}
              <ListItem
                button
                className="list-item-inner"
                onClick={handleScope1Click}
              >
                <ListItemText primary="Scope 1" />
                {openScope1 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openScope1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className="list-item-inner"
                    onClick={handleStationaryCombustionClick} // Add this line
                  >
                    <ListItemText primary="Stationary Combustion" />
                    {openScope11 ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openScope11} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classNames("list-item-inner", {
                          selected: currentPage === "GeneratorDG",
                        })}
                        onClick={() => navigate(`?GeneratorDG`)}
                      >
                        <ListItemText primary="Generator (DG)" />
                      </ListItem>
                      <ListItem
                        button
                        className={classNames("list-item-inner", {
                          selected: currentPage === "BoilerFurnace",
                        })}
                        onClick={() => navigate(`?BoilerFurnace`)}
                      >
                        <ListItemText primary="Boiler & Furnace" />
                      </ListItem>
                      <ListItem
                        button
                        className={classNames("list-item-inner", {
                          selected: currentPage === "LPG",
                        })}
                        onClick={() => navigate(`?LPG`)}
                      >
                        <ListItemText primary="LPG" />
                      </ListItem>
                      <ListItem
                        button
                        className={classNames("list-item-inner", {
                          selected: currentPage === "FuelwoodCoal",
                        })}
                        onClick={() => navigate(`?FuelwoodCoal`)}
                      >
                        <ListItemText primary="Fuelwood/Coal" />
                      </ListItem>
                      <ListItem
                        button
                        className={classNames("list-item-inner", {
                          selected: currentPage === "UploadDocumentsSta",
                        })}
                        onClick={() => navigate("?UploadDocumentsSta")}
                      >
                        <ListItemText primary="Upload Documents" />
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItem
                    button
                    className="list-item-inner"
                    onClick={handleMobileCombustionClick} // Add this line
                  >
                    <ListItemText primary="Mobile Combustion" />
                    {openScope12 ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openScope12} timeout="auto" unmountOnExit>
                    {" "}
                    {/* Add this section */}
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classNames("list-item-inner", {
                          selected: currentPage === "CompanyVehicle",
                        })}
                        onClick={() => navigate(`?CompanyVehicle`)}
                      >
                        <ListItemText primary="Company vehicle" />
                      </ListItem>
                    </List>
                    <ListItem
                      button
                      className={classNames("list-item-inner", {
                        selected: currentPage === "UploadDocumentsMob",
                      })}
                      onClick={() => navigate("?UploadDocumentsMob")}
                    >
                      <ListItemText primary="Upload Documents" />
                    </ListItem>
                  </Collapse>
                  <ListItem
                    button
                    className="list-item-inner"
                    onClick={handleFugitiveEmissionsClick} // Add this line
                  >
                    <ListItemText primary="Fugitive emissions" />
                    {openScope13 ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openScope13} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classNames("list-item-inner", {
                          selected: currentPage === "Refrigerants",
                        })}
                        onClick={() => navigate("?Refrigerants")}
                      >
                        <ListItemText primary="Refrigerants" />
                      </ListItem>
                      <ListItem
                        button
                        className={classNames("list-item-inner", {
                          selected: currentPage === "UploadDocumentsFug",
                        })}
                        onClick={() => navigate("?UploadDocumentsFug")}
                      >
                        <ListItemText primary="Upload Documents" />
                      </ListItem>
                    </List>
                  </Collapse>
                </List>
              </Collapse>
              <ListItem
                button
                className="list-item-inner"
                onClick={handleScope2Click}
              >
                <ListItemText primary="Scope 2" />
                {openScope2 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openScope2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classNames("list-item-inner", {
                      selected: currentPage === "MeteredElectricity",
                    })}
                    onClick={() => navigate("?MeteredElectricity")}
                  >
                    <ListItemText primary="Metered Electricity" />
                  </ListItem>
                  <ListItem
                    button
                    className={classNames("list-item-inner", {
                      selected: currentPage === "UploadDocumentsS2",
                    })}
                    onClick={() => navigate("?UploadDocumentsS2")}
                  >
                    <ListItemText primary="Upload Documents" />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                button
                className="list-item-inner"
                onClick={handleScope3Click}
              >
                <ListItemText primary="Scope 3" />
                {openScope3 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openScope3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classNames("list-item-inner", {
                      selected: currentPage === "Waste",
                    })}
                    onClick={() => navigate("?Waste")}
                  >
                    <ListItemText primary="Waste" />
                  </ListItem>
                  <ListItem
                    button
                    className={classNames("list-item-inner", {
                      selected: currentPage === "FoodAndBeverages",
                    })}
                    onClick={() => navigate("?FoodAndBeverages")}
                  >
                    <ListItemText primary="Food & Beverages" />
                  </ListItem>
                  {/* <ListItem button onClick={() => navigate('?ExternalLaundary')}>
                        <ListItemText primary="External Laundry (optional)" />
                      </ListItem> */}
                  <ListItem
                    button
                    className={classNames("list-item-inner", {
                      selected: currentPage === "Freight",
                    })}
                    onClick={() => navigate("?Freight")}
                  >
                    <ListItemText primary="Freight" />
                  </ListItem>
                  <ListItem
                    button
                    className={classNames("list-item-inner", {
                      selected: currentPage === "SafariTaxi",
                    })}
                    onClick={() => navigate("?SafariTaxi")}
                  >
                    <ListItemText primary="Safari's / Taxi Services" />
                  </ListItem>
                  <ListItem
                    button
                    className={classNames("list-item-inner", {
                      selected: currentPage === "UploadDocumentsS3",
                    })}
                    onClick={() => navigate("?UploadDocumentsS3")}
                  >
                    <ListItemText primary="Upload Documents" />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                button
                className="list-item-inner"
                onClick={handleReductionClick}
              >
                <ListItemText primary="Reduction" />
                {openReduction || currentPage === "Solar" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openReduction || currentPage === "Solar"} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classNames("list-item-inner", {
                      selected: currentPage === "Solar",
                    })}
                    onClick={() => navigate("?Solar")}
                  >
                    <ListItemText primary="Solar" />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                button
                className="list-item-inner"
                onClick={handleSettingClick}
              >
                <ListItemText primary="Setting" />
                {openSetting || currentPage === 'ChangePassword' ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openSetting || currentPage === 'ChangePassword'} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classNames("list-item-inner", {
                      selected: currentPage === "ChangePassword",
                    })}
                    onClick={() => navigate("?ChangePassword")}
                  >
                    <ListItemText primary="Change Password" />
                  </ListItem>
                </List>
              </Collapse>
            </List>
            {/* <ListItem button>
                <ListItemText primary="Reductions" />
              </ListItem> */}
            <ListItem
              button
              className={classNames("list-item-inner", {
                selected: currentPage === "EmissionReport",
              })}
              onClick={() => navigate("?EmissionReport")}
            >
              <ListItemText primary="Emission Report" />
            </ListItem>
            <ListItem
              button
              className="list-item-inner"
              onClick={() => onClickLogout()}
            >
              <ListItemText primary="Logout" />
            </ListItem>
            {/* <ListItem button>
                <ListItemText primary="Reduction Reports" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="GHG Report" />
              </ListItem> */}
          </Drawer>
          {/* Container to hold the page content */}
          <Container
            sx={{
              pt: "64px",
              pl: drawerOpen ? "0" : "0px",
              pr: "0",
              transition: "padding 0.3s",
              width: "100%",
            }}
          >
            <Paper
              style={{ minWidth: "100%" }}
              elevation={3}
              className="paper-layout"
            >
              {renderPage()}
            </Paper>
          </Container>
        </Box>
      }
    </ThemeProvider>
  );
}
