import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { getFreightReportByYear } from "../Utils/api";
import { toast } from "react-toastify";
ChartJS.register(
  CategoryScale,
  LineElement,
  PointElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Net GHG Emission (tCO₂e)           ", // Label for y-axis
        font: {
          weight: "bold",
        },
      },
      ticks: {
        beginAtZero: true,
      },
    },
  },
};

const months = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 },
];

const ChartComponent = ({ year }) => {
  const [data, setdata] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [year]);

  const formateValue = (i, response) => {
    const record = response?.find((j) => Number(j?.month) === Number(i?.value));
    return record?.emissions || 0;
  };

  const getData = async () => {
    setisLoading(true);
    try {
      const freightData = await getFreightReportByYear(year);

      setdata({
        labels: months?.map((i) => i?.name),
        datasets: [
          {
            label: "emissions",
            data: months?.map((i) => formateValue(i, freightData?.data)),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
    setisLoading(false);
  };

  return (
    <div className="mt-2 dashboard col-lg-4 col-md-6 col-sm-12">
      <h1 className="dashbord-title">Freight</h1>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center w-100">
          Loading..
        </div>
      )}
      {data && !isLoading && <Line options={options} data={data} />}
    </div>
  );
};

export default ChartComponent;
