import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
ChartJS.register(
  CategoryScale,
  LineElement,
  PointElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Net GHG Emission (tCO₂e)           ", // Label for y-axis
        font: {
          weight: "bold",
        },
      },
      ticks: {
        beginAtZero: true,
      },
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ChartComponent = ({ year, metered }) => {
  const [data, setdata] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const mergedData = {};

  useEffect(() => {
    const monthlyTotals = [metered || []];

    monthlyTotals.forEach((array) => {
      array.forEach((obj) => {
        const { month, emissions } = obj;

        if (!mergedData[month]) {
          mergedData[month] = {
            emissions: 0,
          };
        }

        if (emissions !== "" && !isNaN(parseFloat(emissions))) {
          mergedData[month].emissions += parseFloat(emissions);
        }
      });
    });

    setdata({
      labels,
      datasets: [
        {
          label: "emissions",
          data: Object.values(mergedData).map((item) =>
            Number(item.emissions || 0)
          ),
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    });
  }, [year, metered]);

  return (
    <div className="mt-2 dashboard col-lg-4 col-md-6 col-sm-12">
      <div className="d-flex justify-content-between align-items-center row">
        <h1 className="dashbord-title">Scope 2</h1>
      </div>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center w-100">
          Loading..
        </div>
      )}
      {data && !isLoading && <Line options={options} data={data} />}
    </div>
  );
};

export default ChartComponent;
