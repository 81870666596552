import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import { useDispatch } from "react-redux";
import { setEmission } from "../../EmissionSlice";
import Form from "react-bootstrap/Form";
import { generateFreight, getFreightReportByYear } from "../../Utils/api";
import { toast } from "react-toastify";

const Freight = () => {
  const dispatch = useDispatch();
  const [year, setyear] = useState("2022");
  const [excelData, setExcelData] = useState([
    {
      month: "January",
      SNo: 1,
      vehicleType: "",
      kmsTravelled: "",
      emissions: "",
    },
    { SNo: "Total", emissions: 0 },
  ]);
  const [showEmissions, setShowEmissions] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getData();
  }, [year]);

  const getData = async () => {
    setLoader(true);
    const { data } = await getFreightReportByYear(year);
    const formattedData = JSON.parse(
      JSON.stringify(
        data?.map((i) => ({
          ...i,
          month: `${months.find((month) => month.value == i.month)?.name}`,
          month_num: i.month,
        }))
      )
    );

    let totalEmissionsValue = formattedData
      ?.reduce((total, item) => total + Number(item?.emissions || 0), 0)
      .toFixed(3);
    handleEmissionChange(year, "emissionFreight", totalEmissionsValue);

    formattedData?.push({
      SNo: "Total",
      emissions: totalEmissionsValue,
    });

    setExcelData(formattedData);
    setLoader(false);
  };

  const onChangeHandler = (e) => {
    setTimeout(() => {
      setyear(e?.target?.value);
    }, 1000);
  };

  const addRow = () => {
    let newData = [...excelData];
    newData.splice(-1, 0, {
      month: "January",
      month_num: 1,
      SNo: newData.length,
      vehicleType: "",
      kmsTravelled: "",
      emissions: "",
    });
    setExcelData(newData);
    setShowEmissions(false);
  };

  const processData = (payload) => {
    let processedData = JSON.parse(JSON.stringify(payload)) || [];

    let totalEmissionsValue = processedData
      ?.reduce((total, item) => total + Number(item?.emissions || 0), 0)
      ?.toFixed(3);

    handleEmissionChange(year, "emissionFreight", totalEmissionsValue);

    processedData?.push({
      SNo: "Total",
      emissions: totalEmissionsValue,
    });

    return processedData;
  };

  const handleEmissionChange = (year, field, value) => {
    dispatch(setEmission({ year, field, value }));
  };

  function sortAndModifyArray(inputArray) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Sort the array based on the month
    inputArray.sort((a, b) => {
      if (a.month && b.month) {
        return months.indexOf(a.month) - months.indexOf(b.month);
      }
      return 0;
    });

    // Fill in missing months with default values
    const defaultValues = { vehicleType: "", kmsTravelled: 0, emissions: 0 };
    const lastObject = inputArray.pop(); // Remove and store the last object
    const sortedArray = [];

    months.forEach((month) => {
      const foundObject = inputArray.find((obj) => obj.month === month);
      if (foundObject) {
        sortedArray.push(foundObject);
      } else {
        sortedArray.push({ month, SNo: 0, ...defaultValues });
      }
    });

    sortedArray.push(lastObject); // Add back the last object

    return sortedArray;
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "S. No.",
        accessor: "SNo",
      },
      {
        Header: "Type of Freight Vehicle",
        accessor: "vehicleType",
      },
      {
        Header: "Number of kms travelled",
        accessor: "kmsTravelled",
      },
      {
        Header: "Emissions (tCO₂e)",
        accessor: "emissions",
        show: showEmissions,
      },
    ],
    [showEmissions]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: excelData });

  const handleChange = (rowIndex, columnId, value) => {
    setExcelData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          const formattedMonth =
            columnId === "month"
              ? months.find((i) => i.name == value)?.value
              : months.find((i) => i.name == row?.month)?.value;
          return {
            ...old[rowIndex],
            [columnId]: value,
            month_num: formattedMonth,
          };
        }
        return row;
      })
    );
  };

  const handleVehicleTypeChange = (rowIndex, vehicleType, name) => {
    handleChange(rowIndex, name, vehicleType);
  };

  useEffect(() => {
    if (excelData?.length < 2) {
      addRow();
    }
  }, [excelData]);

  const generateExcel = async () => {
    try {
      const formData = {
        emissionsData: excelData
          ?.filter((i) => i?.month_num > 0)
          ?.map((i) => ({
            year,
            month: i?.month_num,
            SNo: i?.SNo || 0,
            kmsTravelled: 
            i?.kmsTravelled === "0" || i?.kmsTravelled === 0
              ? Number(0)
              : i?.kmsTravelled
              ? Number(i?.kmsTravelled)
              : null,
            vehicleType: i?.vehicleType || 0,
          })),
      };

      const { data } = await generateFreight(formData);
      processData(JSON.parse(JSON.stringify(data)));
      getData();
      // setShowEmissions(true);
      toast.success("Data generated!!");
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Freight Vehicles");

    worksheet.columns = [
      { header: "Month", key: "month", width: 10 },
      { header: "S. No.", key: "SNo", width: 10 },
      { header: "Type of Freight Vehicle", key: "vehicleType", width: 25 },
      { header: "Number of kms travelled", key: "kmsTravelled", width: 25 },
      { header: "Emissions (tCO₂e)", key: "emissions", width: 25 },
    ];

    excelData.forEach((item) => {
      worksheet.addRow(item);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(blob, "Freight_Vehicles.xlsx");
  };

  return (
    <div className="card-main">
      <h5 className="title-main">Freight</h5>
      <div className="inner-card-main">
        <div className="pt-3 text-center d-flex align-items-center">
          <button className="excelbutton" onClick={addRow}>
            Add Row
          </button>
          <button className="excelbutton" onClick={generateExcel}>
            Calculate Emissions
          </button>
          <button className="excelbutton" onClick={downloadExcel}>
            Download Excel
          </button>
          <div className="">
            <Form.Select
              aria-label="Default select example"
              className="form-select-main"
              onChange={onChangeHandler}
            >
              <option value="2021" selected={year === "2021"}>
                2021
              </option>
              <option value="2022" selected={year === "2022"}>
                2022
              </option>
              <option value="2023" selected={year === "2023"}>
                2023
              </option>
              <option value="2024" selected={year === "2024"}>
                2024
              </option>
            </Form.Select>
          </div>
        </div>
        <div className="mt-3">
          <table {...getTableProps()} style={{ margin: "auto" }}>
            <thead className="p-3">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="table-title" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="" {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell.column.id === "month" ? (
                          showEmissions || row.values.SNo === "Total" ? (
                            <input
                              className="table-input p-2 border-none"
                              type="text"
                              value={cell.value || ""}
                              readOnly
                            />
                          ) : (
                            <select
                              onChange={(e) =>
                                handleVehicleTypeChange(
                                  i,
                                  e.target.value,
                                  "month"
                                )
                              }
                              className="input-width-select p-2"
                            >
                              {months?.map((month, index) => (
                                <option
                                  selected={cell.value === month?.name}
                                  value={month?.name}
                                  key={index}
                                >
                                  {month?.name}
                                </option>
                              ))}
                            </select>
                          )
                        ) : cell.column.id === "vehicleType" ? (
                          showEmissions || row.values.SNo === "Total" ? (
                            <input
                              className="table-input p-2 border-none"
                              type="text"
                              value={cell.value || ""}
                              readOnly
                            />
                          ) : (
                            ["LDV", "MDV", "HDV"].map((type) => (
                              <button
                                className={
                                  excelData[i].vehicleType !== type
                                    ? "excelbutton-main"
                                    : "excelbutton-main selected"
                                }
                                onClick={() =>
                                  handleVehicleTypeChange(
                                    i,
                                    type,
                                    "vehicleType"
                                  )
                                }
                              >
                                {type}
                              </button>
                            ))
                          )
                        ) : cell.column.id === "kmsTravelled" ? (
                          showEmissions || row.values.SNo === "Total" ? (
                            <input
                              className="table-input p-2 border-none"
                              type="text"
                              value={cell.value || ""}
                              readOnly
                            />
                          ) : (
                            <input
                              className="table-input p-2"
                              type={
                                cell.column.id === "kmsTravelled"
                                  ? "number"
                                  : "text"
                              }
                              readOnly={row.values.SNo === "Total"}
                              value={cell.value !== null ? cell.value : null} // Display value or empty string
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*\.?\d{0,3}$/.test(inputValue)) {
                                  handleChange(i, cell.column.id, inputValue);
                                }
                              }}
                            />
                          )
                        ) : (
                          <input
                            className="table-input p-2"
                            type={
                              cell.column.id === "kmsTravelled"
                                ? "number"
                                : "text"
                            }
                            disabled={
                              row.values.SNo === "Total" ||
                              cell.column.id === "SNo" ||
                              cell.column.id === "emissions"
                            }
                            value={cell.value !== null ? cell.value : null} // Display value or empty string
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (/^\d*\.?\d{0,3}$/.test(inputValue)) {
                                handleChange(i, cell.column.id, inputValue);
                              }
                            }}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Freight;
