import React, { useState, useEffect } from 'react';
import { ImageNameDisplay } from '../../Utils/helpers';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trash } from 'react-feather';
import Loader from '../Loader';
import { useTable } from 'react-table';
import { deleteDocs, getDocs, uploadDocs } from '../../Utils/api';
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import { Button, Modal } from 'react-bootstrap';
import CommentIcon from '@mui/icons-material/Comment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import XLSXViewer from './XLSXViewer'
import Editor, { BtnBold, BtnItalic, EditorProvider, Toolbar } from 'react-simple-wysiwyg';

const UploadCSV = () => {
    const [year, setyear] = useState(2022);
    const [excelData, setExcelData] = useState([]);
    const [loader, setLoader] = useState(false);


    const [showXLSX, setShowXLSX] = useState(false);
    const [show, setShow] = useState(0);
    const [commentData, setcommentData] = useState()

    const handleClose = () => {
        setcommentData()
        setShow(0)
    };
    const handleShow = (row) => {
        setcommentData(row?.comment ?? '')
        setShow(row?.id)
    };

    const handleOpenXLSX = (xlsxURL) => {
        setShowXLSX(xlsxURL);
    };

    useEffect(() => {
        getData()
    }, [])

    const assetUrl = window?.location?.origin + '/Excel/sample-sheet.xlsx';
    const dowmloadMultiSheetExcel = async () => {

        const downloadLink = document.createElement('a');
        downloadLink.href = assetUrl;
        downloadLink.download = 'sample-sheet.xlsx';
        downloadLink.click();
    };

    const onClickDelete = async (id) => {

        try {
            const response = await deleteDocs(id);
            if (response?.data?.status) {
                toast.success(response?.data?.message)
            }
            await getData();
        } catch (error) {
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
    };


    const handleDelete = async (id) => {
        try {
            // Show a confirmation dialog before deleting
            confirmAlert({
                title: "Confirm Deletion",
                message: "Are you sure you want to delete this document?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => onClickDelete(id),
                    },
                    {
                        label: "No",
                        onClick: () => {
                            // Do nothing if the user cancels the deletion
                        },
                    },
                ],
            });
        } catch (error) {
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
    };

    useEffect(() => {
        getData()
    }, [])

    const columns = React.useMemo(
        () => [
            {
                Header: "Id",
                accessor: "id",
            },
            {
                Header: "Document Name",
                accessor: "document_name",
            },
            {
                Header: "Status",
                accessor: "status",
            },
            {
                Header: "Year",
                accessor: "year",
            },
            {
                Header: "Comments",
                accessor: "",
            },
            {
                Header: "Action",
                accessor: "",
            },
        ],
        []
    );
    const renderTooltip = (name) => (
        <Tooltip id="image-name-tooltip">{name}</Tooltip>
    );

    const getStatusBadge = (status) => {
        let variant = "";
        switch (status?.toLowerCase()) {
            case "approved":
                variant = "success";
                break;
            case "pending":
                variant = "warning";
                break;
            case "rejected":
                variant = "danger";
                break;
            default:
                variant = "secondary";
        }
        return <Badge bg={variant}>{capitalizeFirstLetter(status)}</Badge>;
    };

    const getData = async () => {
        setLoader(true);
        try {
            const { data } = await getDocs();
            setExcelData(data);
        } catch (error) {
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
        setLoader(false);
    };

    const capitalizeFirstLetter = (str = "") => {
        const words = str?.split(" ");

        const capitalizedWords = words.map((word) => {
            if (word.length > 0) {
                return word[0].toUpperCase() + word.slice(1);
            }
            return word;
        });

        return capitalizedWords.join(" ");
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data: excelData });

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileName = file.name;
            const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

            if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                setLoader(true);
                try {
                    if (file) {
                        const formData = new FormData();
                        formData.append("files", file);
                        formData.append("year", year);

                        const response = await uploadDocs(formData);
                        toast.success(response?.data?.message)
                        await getData();
                    } else {
                        toast.error(`something went wrong`);
                    }
                } catch (error) {
                    toast.error(error?.response?.data?.message || "Something went wrong");
                }
                setLoader(false);
            } else {
                toast.error('Please select a valid .xlsx or .xls file.');
                event.target.value = null;
            }
        }

    };
    return (
        <div>
            <div>
                <h4>Download Sample CSV</h4>
                <button className="excelbutton" onClick={dowmloadMultiSheetExcel}>Download CSV</button>
            </div>
            <div>
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    accept=".xlsx,.xls" // Specify allowed file types
                    onChange={handleFileChange}
                />
                <button
                    className="excelbutton"
                    disabled={loader}
                    onClick={() => document.getElementById("fileInput").click()}
                >
                    Upload Documents
                </button>
                <label className="pb-3 mx-2 text-left">
                    Year:
                    <select
                        value={year}
                        onChange={(e) => {
                            setyear(e.target.value);
                        }}
                        className="form-control select-box"
                    >
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                    </select>
                </label>
            </div>
            <div className="mt-5">
                {loader ? (
                    <>
                        <div style={{ margin: "0 auto" }}>
                            <Loader />
                            <div className="text-center">Please Do not close this Tab...</div>
                        </div>
                    </>
                ) : (
                    <table
                        className="inner-card p-3"
                        {...getTableProps()}
                        style={{ margin: "auto" }}
                    >
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {excelData?.length === 0 ? (
                                        <th
                                            className="table-title"
                                            colSpan={headerGroup.headers?.length}
                                        >
                                            No Records Found
                                        </th>
                                    ) : (
                                        headerGroup.headers.map((column) => (
                                            <th className="table-title" {...column.getHeaderProps()}>
                                                {column.render("Header")}
                                            </th>
                                        ))
                                    )}
                                </tr>
                            ))}
                        </thead>
                        <tbody className="col-md-10 col-sm-8" {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>
                                                <div className="table-input p-2 col-md-11 col-sm-11 ">
                                                    {cell?.column?.Header === "Status" &&
                                                        getStatusBadge(cell.value)}
                                                    {cell?.column?.Header === "Action" &&
                                                        (
                                                            <>
                                                                {/* <VisibilityIcon  className="pointer" onClick={() => { handleOpenXLSX(row.values?.presigned_url) }} /> */}
                                                                <DownloadIcon className="pointer" />
                                                            </>
                                                        )}{cell?.column?.Header === "Action" &&
                                                            row?.values?.status !== "approved" && (

                                                                <Trash color="red" className="pointer" onClick={() =>
                                                                    handleDelete(row?.values?.id)
                                                                } />
                                                            )}
                                                    {cell?.column?.Header === "year" &&
                                                        cell.value
                                                    }
                                                    {cell?.column?.Header === "Comments" &&
                                                        <>
                                                            <span onClick={() => { handleShow(row?.original) }}>
                                                                <CommentIcon />
                                                            </span>
                                                           {row?.original?.comment && <span class="badge badge-pill badge-success message-badge"></span>}

                                                        </>
                                                    }
                                                    {cell?.column?.Header === "Document Name" &&
                                                        <>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={renderTooltip(cell?.value)}
                                                            ><div>
                                                                    <ImageNameDisplay imageName={cell?.value} />
                                                                </div>

                                                            </OverlayTrigger>
                                                        </>}
                                                    {cell?.column?.Header === "Id" && cell.value}
                                                    {cell?.column?.Header === "Year" && cell.value}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {showXLSX && (
                <XLSXViewer src={assetUrl} />
            )}
            <Modal show={show} onHide={handleClose} animation={false} style={{ paddingTop: '15%' }}>
                <Modal.Header closeButton  >
                    <Modal.Title>Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: 140, maxHight: 300 }}>
                        {commentData ? <div
                            dangerouslySetInnerHTML={{ __html: commentData }}
                        /> : <p className="text-center">No Data Found</p>}
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UploadCSV;