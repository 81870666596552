import api, { formData } from "./axios";

// user auth
export const signup = (values) => api(`users/signup`, "POST", values);
export const signin = (values) => api(`users/signin`, "POST", values);
export const authenticate = () => api(`users/authenticate`, "GET");

//change password
export const changePassword = (values) => api(`users/update-password`, "POST", values);

// generator APIs
export const generateDG = (values) => api(`generators`, "POST", values);
export const getGeneratorReportByYear = (year) => api(`generators/${year}`, "GET");

// Boiler furnace APIs
export const generateBoilerFurnace = (values) => api(`boilerFurnace`, "POST", values);
export const getBoilerFurnaceReportByYear = (year) => api(`boilerFurnace/${year}`, "GET");

// freight APIs
export const generateFreight = (values) => api(`freight`, "POST", values);
export const getFreightReportByYear = (year) => api(`freight/${year}`, "GET");

// lpg APIs
export const generateLPG = (values) => api(`lpg`, "POST", values);
export const getLPGReportByYear = (year) => api(`lpg/${year}`, "GET");

// lpg APIs
export const generateCompanyvehicle = (values) => api(`companyvehicle`, "POST", values);
export const getCompanyvehicleReportByYear = (year) => api(`companyvehicle/${year}`, "GET");
export const updateCompanyVehiclePrefrence = (data,userId) => api(`users/${userId}`, "PUT",data);

// foodAndBeverage APIs
export const generateFoodAndBeverage = (values) => api(`foodAndBeverage`, "POST", values);
export const getFoodAndBeverageReportByYear = (year) => api(`foodAndBeverage/${year}`, "GET");

// safariTaxi APIs
export const generateSafariTaxi = (values) => api(`safariTaxi`, "POST", values);
export const getSafariTaxiReportByYear = (year) => api(`safariTaxi/${year}`, "GET");

// waste APIs
export const generateWaste = (values) => api(`waste`, "POST", values);
export const getWasteReportByYear = (year) => api(`waste/${year}`, "GET");

// refrigerant APIs
export const generateRefrigerant = (values) => api(`refrigerant`, "POST", values);
export const getRefrigerantReportByYear = (year) => api(`refrigerant/${year}`, "GET");

// metered electricity APIs
export const generateMeteredElectricity = (values) => api(`meteredElectricity`, "POST", values);
export const getMeteredElectricityReportByYear = (year) => api(`meteredElectricity/${year}`, "GET");

// fuelwood APIs
export const generateFuelwoodCoal = (values) => api(`fuelwoodCoal`, "POST", values);
export const getFuelwoodCoalReportByYear = (year) =>  api(`fuelwoodCoal/${year}`, "GET");

// solar APIs
export const generateSolar = (values) => api(`solar`, "POST", values);
export const getSolarReportByYear = (year) => api(`solar/${year}`, "GET");

// fugitive emission APIs
export const uploadFugitiveEmissions = (values) => formData(`fugitiveEmission/upload`, "POST", values);
export const getFugitiveEmissions = (year) => api(`fugitiveEmission/${year}`, "GET");
export const deleteFugitiveEmissions = (id) => api(`fugitiveEmission/${id}`, "DELETE");

// stationary combustion APIs
export const uploadStationaryCombustion = (values) => formData(`stationaryCombustion/upload`, "POST", values);
export const getStationaryCombustion = (year) => api(`stationaryCombustion/${year}`, "GET");
export const deleteStationaryCombustion = (id) => api(`stationaryCombustion/${id}`, "DELETE");

// mobile combustion APIs
export const uploadMobileCombustion = (values) => formData(`mobileCombustion/upload`, "POST", values);
export const getMobileCombustion = (year) => api(`mobileCombustion/${year}`, "GET");
export const deleteMobileCombustion = (id) => api(`mobileCombustion/${id}`, "DELETE");

// s2 docs APIs
export const uploadS2docs = (values) => formData(`s2docs/upload`, "POST", values);
export const getS2docs = (year) => api(`s2docs/${year}`, "GET");
export const deleteS2docs = (id) => api(`s2docs/${id}`, "DELETE");

// s3 docs APIs
export const uploadS3docs = (values) => formData(`s3docs/upload`, "POST", values);
export const getS3docs = (year) => api(`s3docs/${year}`, "GET");
export const deleteS3docs = (id) => api(`s3docs/${id}`, "DELETE");

export const uploadDocs = (values) => formData(`docs/upload`, "POST", values);
export const getDocs = () => api(`docs`, "GET");
export const deleteDocs = (id) => api(`docs/${id}`, "DELETE");