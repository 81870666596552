import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import ExcelJS from "exceljs";
import { useDispatch } from "react-redux";
import { setEmission } from "../../EmissionSlice";
import Loader from "../Loader";
import {
  generateBoilerFurnace,
  getBoilerFurnaceReportByYear,
} from "../../Utils/api";
import { toast } from "react-toastify";
import classNames from "classnames";

const BoilerFurnace = () => {
  const dispatch = useDispatch();
  const furnaceNVCFactor = 0.0295;

  const [year, setYear] = useState(2022);
  const [emissionFactor, setEmissionFactor] = useState(81.6);
  const [excelData, setExcelData] = useState([]);
  const [totalEmissions, setTotalEmissions] = useState(0);
  const [showEmissions, setShowEmissions] = useState(false);
  const [totalIndex, setTotalIndex] = useState(null);
  const [loader, setLoader] = useState(false);

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const calculateTotalEmissions = (data) => {
    const totalEmissionsValue = data
      .reduce((total, item) => total + (Number(item?.emissions) || 0), 0)
      .toFixed(3);
    return totalEmissionsValue;
  };

  useEffect(() => {
    updateExcel(year);
  }, []);

  useEffect(() => {
    getData();
  }, [year]);

  const getData = async () => {
    setLoader(true);
    const { data } = await getBoilerFurnaceReportByYear(year);
    const formattedData = JSON.parse(
      JSON.stringify(
        data?.map((i) => ({
          ...i,
          month: `${
            months.find((month) => month.value == i.month)?.name
          } ${year}`,
          month_num: i.month,
        }))
      )
    );

    let updatedExcelData = formattedData;

    if (updatedExcelData?.length < 12) {
      generateExcel(false);
    } else {
      if (!updatedExcelData.some((item) => item.month === "Total")) {
        const totalRow = {
          month: "Total",
          emissions: calculateTotalEmissions(updatedExcelData),
        };
        updatedExcelData.push(totalRow);
        setTotalIndex(updatedExcelData.length - 1);
      }
    }

    setExcelData(updatedExcelData);
    setLoader(false);
  };

  const processData = (payload) => {
    const data = JSON.parse(JSON.stringify(payload));

    // Calculate total emissions
    let totalEmissionsValue = data?.reduce(
      (total, item) => total + Number(item?.emissions),
      0
    );

    // Update the total emissions in the Redux store
    handleEmissionChange(year, "totalEmissionsValue", totalEmissionsValue);
    return data;
  };

  const handleEmissionChange = (year, field, value) => {
    dispatch(setEmission({ year, field, value }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "Charcoal Used  (kg)",
        accessor: "charcoalUsed",
      },
      {
        Header: "Emissions (tCO₂e)",
        accessor: "emissions",
        show: showEmissions,
      },
    ],
    [showEmissions]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: excelData });

  const handleChange = (rowIndex, columnId, value) => {
    setExcelData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const generateExcel = async (showMsg = true) => {
    var newExceldata = excelData;
    if (excelData?.length === 0) {
      const Data = months.map((month) => ({
        month: `${month?.name} ${year}`,
        month_num: month?.value,
        charcoalUsed: "",
      }));
      newExceldata = Data;
    }

    try {
      const formData = {
        emissionFactor: emissionFactor,
        emissionsData: newExceldata
          ?.filter((i) => i?.month_num > 0)
          ?.map((i) => ({
            year,
            month: i?.month_num,
            charcoalUsed:
              i?.charcoalUsed === "0" || i?.charcoalUsed === 0
                ? Number(0)
                : i?.charcoalUsed
                ? Number(i?.charcoalUsed)
                : null,
          })),
      };

      const { data } = await generateBoilerFurnace(formData);
      let processedExcelData = processData(JSON.parse(JSON.stringify(data)));

      let totalEmissionsValue = processedExcelData
        ?.reduce(
          (total, item) => Number(total || 0) + Number(item?.emissions || 0),
          0
        )
        ?.toFixed(3);

      processedExcelData = processedExcelData.map((item) => {
        const formattedMonth = months.find((i) => i.value == item.month);
        return {
          ...item,
          month_num: item?.month,
          month: `${formattedMonth?.name} ${year}`,
          emissions:
            Number(item?.emissions || 0) !== null
              ? Number(item?.emissions || 0)?.toFixed(3)
              : "",
        };
      });
      setTotalEmissions(totalEmissionsValue);
      setShowEmissions(true);

      if (totalIndex !== null) {
        processedExcelData[totalIndex] = {
          month: "Total",
          emissions: totalEmissionsValue,
        };
      } else {
        processedExcelData.push({
          month: "Total",
          emissions: totalEmissionsValue,
        });
        setTotalIndex(processedExcelData.length - 1); // Update 'Total' row index
      }

      setExcelData(processedExcelData);

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Diesel Consumption");

      worksheet.columns = [
        { header: "Month", key: "month", width: 15 },
        {
          header: "Diesel Consumption (liters)",
          key: "charcoalUsed",
          width: 25,
        },
        { header: "Emissions (tCO₂e)", key: "emissions", width: 25 },
      ];

      processedExcelData.forEach((item) => {
        worksheet.addRow(item);
      });

      worksheet.addRow({ month: "Total", emissions: totalEmissionsValue });
      if (showMsg) {
        toast.success("Data generated!!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const updateExcel = (currentYear) => {
    const newExcelData = months.map((month) => ({
      month: `${month?.name} ${currentYear}`,
      month_num: month?.value,
      charcoalUsed: "",
    }));
    setExcelData(newExcelData);
    setShowEmissions(false);
    setTotalIndex(null);
  };

  return (
    <div className="card-main">
      <h5 className="title-main pb-0">Boiler & Furnance</h5>
      <div className="d-flex justify-content-center lable-main">
        <label className="pb-3 mx-2 text-left">
          Year:
          <select
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
              updateExcel(e.target.value);
            }}
            className="form-control select-box"
          >
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </label>

        <label className=" text-left">
          Emission Factor:
          <input
            type="number"
            value={emissionFactor}
            onChange={(e) => setEmissionFactor(e.target.value)}
            className="form-control w-30"
          />
        </label>
      </div>
      <div className="pt-3 text-center">
        <button className="excelbutton" onClick={generateExcel}>
          Calculate Emissions
        </button>
      </div>
      <div className="mt-5">
        {loader ? (
          <Loader />
        ) : (
          <table
            className="inner-card p-3"
            {...getTableProps()}
            style={{ margin: "auto" }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="table-title" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="col-md-10 col-sm-8" {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell?.column?.Header !== "Charcoal Used  (kg)" ? (
                          <div className="table-input p-2 col-md-11 col-sm-11 input-border">
                            {cell.value}
                          </div>
                        ) : (
                          <input
                            type={
                              cell.column.id === "charcoalUsed"
                                ? "number"
                                : "text"
                            }
                            step="0.001" // Allow only three decimal places
                            className={classNames({
                              "table-input p-2 col-md-11 col-sm-11":
                                cell?.row?.original?.month !== "Total",
                              "table-input p-2 col-md-11 col-sm-11 d-none":
                                cell?.row?.original?.month === "Total",
                            })}
                            value={cell.value !== null ? cell.value : null} // Display value or empty string
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (/^\d*\.?\d{0,3}$/.test(inputValue)) {
                                handleChange(i, cell.column.id, inputValue);
                              }
                            }}
                            readOnly={cell?.row?.original?.month === "Total"}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default BoilerFurnace;
