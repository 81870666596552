import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import ExcelJS from "exceljs";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { setEmission } from "../../EmissionSlice";
import {
  generateFuelwoodCoal,
  getFuelwoodCoalReportByYear,
} from "../../Utils/api";
import { toast } from "react-toastify";
import Loader from "../Loader";

const coalTypes = {
  Anthracite: { ncv: 0.0267, ef: 98.3 },
  "Coking Coal": { ncv: 0.0282, ef: 94.6 },
  "Other Bituminous Coal": { ncv: 0.0258, ef: 94.6 },
  "Sub-Bituminous Coal": { ncv: 0.0189, ef: 96.1 },
  Lignite: { ncv: 0.0119, ef: 101 },
  "Brown Coal Briquettes": { ncv: 0.0207, ef: 97.5 },
  "Wood/Wood Waste": { ncv: 0.0156, ef: 112 },
  Charcoal: { ncv: 0.0295, ef: 112 },
};

const coalTypesOptions = [
  { name: "Anthracite", value: "anthracite" },
  { name: "Coking Coal", value: "coking_coal" },
  { name: "Other Bituminous Coal", value: "other_bituminous_coal" },
  { name: "Sub-Bituminous Coal", value: "sub-bituminous_coal" },
  { name: "Lignite", value: "lignite" },
  { name: "Brown Coal Briquettes", value: "brown_coal_briquettes" },
  { name: "Wood/Wood Waste", value: "wood/wood_waste" },
  { name: "Charcoal", value: "charcoal" },
];

const FuelwoodCoal = () => {
  const dispatch = useDispatch();
  const [year, setYear] = useState(2022);
  const [excelData, setExcelData] = useState([]);
  const [showEmissions, setShowEmissions] = useState(false);
  const [totalIndex, setTotalIndex] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loader, setloader] = useState(true);

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const processData = (data) => {
    let processedData = data.map((item) => {
      let emissions = 0;
      for (const [coal, { ncv, ef }] of Object.entries(coalTypes)) {
        let coalEmissions = item[coal] * ncv * ef;
        emissions += coalEmissions;
        item[coal + "Emissions"] = coalEmissions;
      }
      return {
        ...item,
        emissions,
      };
    });

    let emissionsValue = processedData
      .reduce((total, item) => total + item.emissions, 0)
      .toFixed(3);
    // Update the total emissions in the Redux store
    handleEmissionChange(year, "emissionFuelwoodCoal", emissionsValue);
    return processedData;
  };

  const handleEmissionChange = (year, field, value) => {
    dispatch(setEmission({ year, field, value }));
  };

  useEffect(() => {
    if (selectedOptions?.length > 0) {
      localStorage.setItem(
        "selectedOptionsFuelWood",
        JSON.stringify(selectedOptions)
      );
    }

    if (year) {
      localStorage.setItem("selectedYear", JSON.stringify(year));
    }
  }, [selectedOptions, year]);

  useEffect(() => {
    const storedOptions = JSON.parse(
      localStorage.getItem("selectedOptionsFuelWood")
    );
    const storedYear = JSON.parse(localStorage.getItem("selectedYear"));

    if (storedOptions) {
      setSelectedOptions(storedOptions);
    }

    if (storedYear) {
      setYear(storedYear);
    }
  }, []);

  const calculateTotalEmissions = (data) => {
    const totalEmissionsValue = data
      .reduce((total, item) => total + Number(item?.emissions), 0)
      .toFixed(3);
    return totalEmissionsValue;
  };

  useEffect(() => {
    getData();
  }, [year]);

  const getData = async () => {
    setloader(true);
    const { data } = await getFuelwoodCoalReportByYear(year);
    let formattedData = JSON.parse(
      JSON.stringify(
        data?.map((i) => ({
          ...i,
          month: `${
            months.find((month) => month?.value == i?.month)?.name
          } ${year}`,
          month_num: i.month,
          Anthracite: i?.anthracite,
          "Coking Coal": i?.cooking_coal,
          "Other Bituminous Coal": i?.other_bituminous_coal,
          "Sub-Bituminous Coal": i?.sub_bituminous_coal,
          Lignite: i?.lignite,
          "Brown Coal Briquettes": i?.brown_coal_briquettes,
          "Wood/Wood Waste": i?.wood_waste,
          Charcoal: i?.charcoal,
        }))
      )
    );

    let updatedExcelData = formattedData;

    if (updatedExcelData?.length < 12) {
      await handleGenerateExcel(false, false);
      // After generating data, fetch again if needed
      const updatedData = await getFuelwoodCoalReportByYear(year);
      updatedExcelData = JSON.parse(
        JSON.stringify(
          updatedData?.data?.map((i) => ({
            ...i,
            month: `${
              months.find((month) => month?.value == i?.month)?.name
            } ${year}`,
            month_num: i.month,
            Anthracite: i?.anthracite,
            "Coking Coal": i?.cooking_coal,
            "Other Bituminous Coal": i?.other_bituminous_coal,
            "Sub-Bituminous Coal": i?.sub_bituminous_coal,
            Lignite: i?.lignite,
            "Brown Coal Briquettes": i?.brown_coal_briquettes,
            "Wood/Wood Waste": i?.wood_waste,
            Charcoal: i?.charcoal,
          }))
        )
      );
    } else {
      if (!updatedExcelData.some((item) => item.month === "Total")) {
        const totalRow = {
          month: "Total",
          emissions: calculateTotalEmissions(updatedExcelData),
        };
        updatedExcelData.push(totalRow);
        setTotalIndex(updatedExcelData.length - 1);
      }
    }

    setExcelData(updatedExcelData);
    setloader(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      ...selectedOptions.map((option) => ({
        Header: `Net ${option.value} used in kg`,
        accessor: option.value,
      })),
      {
        Header: "Total Emissions (tCO₂e)",
        accessor: "emissions",
        show: showEmissions,
      },
    ],
    [selectedOptions, showEmissions]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: excelData });

  const handleChange = (rowIndex, columnId, value) => {
    if (selectedOptions.find((option) => option.value === columnId)) {
      setExcelData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        })
      );
    }
  };

  const handleGenerateExcel = async (showMsg = true, loadDefault = true) => {
    var newExceldata = excelData;
    if (excelData?.length === 0) {
      const object = coalTypesOptions.reduce(
        (obj, item) => Object.assign(obj, { [item.name]: null }),
        {}
      );
      const Data = months.map((month) => ({
        month: `${month?.name} ${year}`,
        month_num: month?.value,
        ...object,
      }));
      newExceldata = Data;
    }
    try {
      const formData = {
        emissionsData: newExceldata
          ?.filter((i) => i?.month_num > 0)
          ?.map((i) => ({
            year,
            month: i?.month_num,
            anthracite: i?.["Anthracite"],
            cooking_coal: i?.["Coking Coal"],
            other_bituminous_coal: i?.["Other Bituminous Coal"],
            sub_bituminous_coal: i?.["Sub-Bituminous Coal"],
            lignite: i?.["Lignite"],
            brown_coal_briquettes: i?.["Brown Coal Briquettes"],
            wood_waste: i?.["Wood/Wood Waste"],
            charcoal: i?.["Charcoal"],
          })),
      };

      const { data } = await generateFuelwoodCoal(formData);
      let processedExcelData = processData(JSON.parse(JSON.stringify(data)));

      if (loadDefault) {
        await getData();
      }

      let emissionsValue = processedExcelData
        .reduce((total, item) => total + item.emissions, 0)
        .toFixed(3);

      processedExcelData = processedExcelData.map((item) => {
        return {
          ...item,
          emissions: item.emissions !== null ? item.emissions.toFixed(3) : "",
        };
      });

      setShowEmissions(true);

      if (totalIndex !== null) {
        processedExcelData[totalIndex] = {
          month: "Total",
          emissions: emissionsValue,
        };
      } else {
        processedExcelData.push({
          month: "Total",
          emissions: emissionsValue,
        });
        setTotalIndex(processedExcelData.length - 1);
      }

      //   setExcelData(processedExcelData);

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Fuelwood Coal Usage");

      let selectedRefrigerants = selectedOptions.map((option) => option.value);
      worksheet.columns = [
        { header: "Month", key: "month", width: 15 },
        ...selectedRefrigerants.map((gas) => ({
          header: `Net ${gas} used in kg`,
          key: gas,
          width: 25,
        })),
        { header: "Total Emissions (tCO₂e)", key: "emissions", width: 25 },
      ];

      processedExcelData.forEach((item) => {
        worksheet.addRow(item);
      });

      worksheet.addRow({ month: "Total", emissions: emissionsValue });

      if (showMsg) {
        toast.success("Data generated!!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const updateExcel = (currentYear) => {
    const newExcelData = months.map((month) => ({
      month: `${month?.name} ${currentYear || year}`,
      month_num: month?.value,
      ...coalTypesOptions.reduce(
        (acc, option) => ({ ...acc, [option.name]: null }),
        {}
      ),
      emissions: "",
    }));
    setExcelData(newExcelData);
    setShowEmissions(false);
    setTotalIndex(null);
  };

  const selectOptions = Object.keys(coalTypes).map((coal) => ({
    value: coal,
    label: coal,
  }));

  const customFilter = (option, rawInput) => {
    const regex = new RegExp(rawInput, "i");
    return regex.test(option.label);
  };

  return (
    <div className="card-main">
      <h5 className="sub-header title-main">Fuelwood and Coal</h5>
      <div className="d-flex justify-content-center lable-main">
        <div className="pb-3 mx-2">
          <label className="text-left ">Year:</label>
          <select
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
              updateExcel(e.target.value);
            }}
            className="form-control select-box"
          >
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </div>
        <div className="input-width">
          <label className="text-left">Select Coal Type:</label>
          <Select
            id="coalType"
            options={selectOptions}
            isMulti
            value={selectedOptions} // Set initial selected options
            onChange={setSelectedOptions}
            filterOption={customFilter}
          />
        </div>
      </div>
      <div className="pt-3 text-center">
        <button className="excelbutton" onClick={handleGenerateExcel}>
          Calculate Emissions
        </button>
      </div>
      <div className="mt-5 d-flex justify-content-center">
        {loader ? (
          <Loader />
        ) : (
          <table
            className="inner-card-table p-3 text-center"
            {...getTableProps()}
          >
            <thead className="col-md-10 col-sm-8">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="table-title" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="col-md-10 col-sm-8" {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell?.column?.Header === "Total Emissions (tCO₂e)" ||
                        cell?.column?.Header === "Month" ? (
                          <div className="table-input p-2 col-md-11 col-sm-11 input-border">
                            {cell.value}
                          </div>
                        ) : (
                          <input
                            type={
                              cell.column.id === "month" ? "text" : "number"
                            }
                            className="table-input p-2 col-md-11 col-sm-11"
                            value={cell.value !== null ? cell.value : null} // Display value or empty string
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (/^\d*\.?\d{0,3}$/.test(inputValue)) {
                                handleChange(i, cell.column.id, inputValue);
                              }
                            }}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default FuelwoodCoal;
