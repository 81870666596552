// emissionsSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const emissionsSlice = createSlice({
  name: 'emissions',
  initialState: {},
  reducers: {
    setEmission: (state, action) => {
      const { year, field, value } = action.payload;
      if (!state[year]) {
        state[year] = {};
      }
      state[year][field] = value;
    },
  },
});

export const { setEmission } = emissionsSlice.actions;

export default emissionsSlice.reducer;
