import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import { useDispatch } from "react-redux";
import { setEmission } from "../../EmissionSlice";
import Form from "react-bootstrap/Form";
import {
  generateCompanyvehicle,
  getCompanyvehicleReportByYear,
  updateCompanyVehiclePrefrence,
} from "../../Utils/api";
import { toast } from "react-toastify";
import Loader from "../Loader";
import classNames from "classnames";
import SwitchToLiters from "./SwitchToLiters";
import useAuth from "../../Utils/hooks/useAuth";

const formatString = (input) => {
  // Replace underscores with spaces
  let stringWithSpaces = input.replace(/_/g, " ");

  // Split the string into words
  let words = stringWithSpaces.split(" ");

  // Capitalize the first letter of each word
  let capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words back together with spaces
  let formattedString = capitalizedWords.join(" ");

  return formattedString;
};

const Companyvehicle = () => {
  const dispatch = useDispatch();
  const [year, setyear] = useState("2022");
  const { user, authenticateUser } = useAuth();
  const [excelData, setExcelData] = useState([
    {
      month: "January",
      SNo: 1,
      vehicleType: "",
      vehicleDetails: "",
      fuelType: "",
      kmsDriven: "",
      emissions: "",
    },
    { SNo: "Total", emissions: 0 },
  ]);
  const [switchToLiters, setSwitchToLiters] = useState(false);
  const [showEmissions, setShowEmissions] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);

  const sampleArray = [
    {
      name: "scooter_less_than_110CC",
      value: 0.0368,
      fuelType: "petrol",
      vehicleType: "two_wheeler",
      label: "Scooter <110CC",
    },
    {
      name: "scooter_less_than_150CC",
      value: 0.0387,
      fuelType: "petrol",
      vehicleType: "two_wheeler",
      label: "Scooter <150CC",
    },
    {
      name: "motorcycle_less_than_110CC",
      value: 0.0358,
      fuelType: "petrol",
      vehicleType: "two_wheeler",
      label: "Motorcycle <110CC",
    },
    {
      name: "motorcycle_less_than_125CC",
      value: 0.0319,
      fuelType: "petrol",
      vehicleType: "two_wheeler",
      label: "Motorcycle <125CC",
    },
    {
      name: "motorcycle_less_than_135CC",
      value: 0.0356,
      fuelType: "petrol",
      vehicleType: "two_wheeler",
      label: "Motorcycle <135CC",
    },
    {
      name: "motorcycle_less_than_200CC",
      value: 0.0458,
      fuelType: "petrol",
      vehicleType: "two_wheeler",
      label: "Motorcycle <200CC",
    },
    {
      name: "motorcycle_less_than_300CC",
      value: 0.0595,
      fuelType: "petrol",
      vehicleType: "two_wheeler",
      label: "Motorcycle <300CC",
    },
    {
      name: "motorcycle_less_than_500CC",
      value: 0.0597,
      fuelType: "petrol",
      vehicleType: "two_wheeler",
      label: "Motorcycle <500CC",
    },
    {
      name: "three_wheelers_petrol",
      value: 0.1135,
      fuelType: "petrol",
      vehicleType: "three_wheeler",
      label: "Petrol",
    },
    {
      name: "three_wheelers_diesel",
      value: 0.1322,
      fuelType: "diesel",
      vehicleType: "three_wheeler",
      label: "Diesel",
    },
    {
      name: "three_wheelers_cng",
      value: 0.10768,
      fuelType: "cng",
      vehicleType: "three_wheeler",
      label: "CNG",
    },
    {
      name: "petrol_small_car_less_than_800CC",
      value: 0.111,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Small <800CC Petrol",
    },
    {
      name: "cng_small_car_less_than_800CC",
      value: 0.068,
      fuelType: "cng",
      vehicleType: "passenger_car",
      label: "Small <800CC CNG",
    },
    {
      name: "lpg_small_car_less_than_800CC",
      value: 0.149,
      fuelType: "lpg",
      vehicleType: "passenger_car",
      label: "Small <800CC LPG",
    },
    {
      name: "petrol_hatchback_car_less_than_1000CC",
      value: 0.127,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Hatchback <1000CC Petrol",
    },
    {
      name: "petrol_hatchback_car_less_than_1400CC",
      value: 0.14,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Hatchback <1400CC Petrol",
    },
    {
      name: "petrol_hatchback_car_less_than_1600CC",
      value: 0.162,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Premium Hatchback <1600CC Petrol",
    },
    {
      name: "petrol_compact_suv_car_less_than_1600CC",
      value: 0.166,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Compact SUV <1600CC Petrol",
    },
    {
      name: "petrol_gypsy_car_less_than_1298CC",
      value: 0.204,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Gypsy 1298CC Petrol",
    },
    {
      name: "petrol_seden_car_less_than_1400CC",
      value: 0.153,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Sedan <1400CC Petrol",
    },
    {
      name: "petrol_seden_car_less_than_1600CC",
      value: 0.153,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Sedan <1600CC Petrol",
    },
    {
      name: "petrol_seden_car_less_than_2000CC",
      value: 0.161,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Sedan <2000CC Petrol",
    },
    {
      name: "petrol_seden_car_less_than_2500CC",
      value: 0.176,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Sedan <2500CC Petrol",
    },
    {
      name: "petrol_suv_car_less_than_3000CC",
      value: 0.213,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "SUV <3000CC Petrol",
    },
    {
      name: "petrol_muv_car_less_than_2000CC",
      value: 0.23,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "MUV <2000CC Petrol",
    },
    {
      name: "petrol_premium_suv_car_less_than_2000CC",
      value: 0.208,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Premium SUV <2000CC Petrol",
    },
    {
      name: "petrol_premium_suv_car_less_than_3000CC",
      value: 0.279,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Premium SUV <3000CC Petrol",
    },
    {
      name: "petrol_premium_suv_car_greater_than_3000CC",
      value: 0.289,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Premium SUV >3000CC Petrol",
    },
    {
      name: "petrol_premium_sedan_car_less_than_2000CC",
      value: 0.207,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Premium Sedan <2000CC Petrol",
    },
    {
      name: "petrol_premium_sedan_car_less_than_3000CC",
      value: 0.209,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Premium Sedan <3000CC Petrol",
    },
    {
      name: "petrol_premium_sedan_car_greater_than_3000CC",
      value: 0.27,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Premium Sedan >3000CC Petrol",
    },
    {
      name: "petrol_hybrid_car_less_than_2000CC",
      value: 0.103,
      fuelType: "petrol",
      vehicleType: "passenger_car",
      label: "Hybrid <2000CC Petrol",
    },
    {
      name: "diesel_hatchback_car_less_than_1000CC",
      value: 0.113,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Hatchback <1000CC Diesel",
    },
    {
      name: "diesel_hatchback_car_less_than_1400CC",
      value: 0.126,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Hatchback <1400CC Diesel",
    },
    {
      name: "diesel_hatchback_car_less_than_1600CC",
      value: 0.147,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Hatchback <1600CC Diesel",
    },
    {
      name: "diesel_sedan_car_less_than_1400CC",
      value: 0.131,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Sedan <1400CC Diesel",
    },
    {
      name: "diesel_sedan_car_less_than_1600CC",
      value: 0.141,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Sedan <1600CC Diesel",
    },
    {
      name: "diesel_sedan_car_less_than_2000CC",
      value: 0.16,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Sedan <2000CC Diesel",
    },
    {
      name: "diesel_premium_sedan_car_less_than_2000CC",
      value: 0.177,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Premium Sedan <2000CC Diesel",
    },
    {
      name: "diesel_premium_sedan_car_less_than_2500CC",
      value: 0.163,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Premium Sedan <2500CC Diesel",
    },
    {
      name: "diesel_premium_sedan_car_less_than_3000CC",
      value: 0.248,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Premium Sedan <3000CC Diesel",
    },
    {
      name: "diesel_muv_car_less_than_1400CC",
      value: 0.157,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "MUV <1400CC Diesel",
    },
    {
      name: "diesel_muv_car_less_than_2500CC",
      value: 0.216,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "MUV <2500CC Diesel",
    },
    {
      name: "diesel_maxi_van",
      value: 0.226,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Maxi Van Diesel",
    },
    {
      name: "diesel_suv_car_less_than_2000CC",
      value: 0.201,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "SUV <2000CC Diesel",
    },
    {
      name: "diesel_suv_car_less_than_2500CC",
      value: 0.21,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "SUV <2500CC Diesel",
    },
    {
      name: "diesel_suv_car_less_than_3000CC",
      value: 0.22,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "SUV <3000CC Diesel",
    },
    {
      name: "diesel_premium_suv_car_less_than_2000CC",
      value: 0.18,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Premium SUV <2000CC Diesel",
    },
    {
      name: "diesel_premium_suv_car_less_than_2500CC",
      value: 0.215,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Premium SUV <2500CC Diesel",
    },
    {
      name: "diesel_premium_suv_car_less_than_3000CC",
      value: 0.24,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Premium SUV <3000CC Diesel",
    },
    {
      name: "diesel_premium_suv_car_greater_than_3000CC",
      value: 0.29,
      fuelType: "diesel",
      vehicleType: "passenger_car",
      label: "Premium SUV >3000CC Diesel",
    },
    {
      name: "freight_ldv",
      value: 0.307,
      fuelType: "petrol",
      vehicleType: "freight_vehicles",
      label: "LDV (<3.5T)",
    },
    {
      name: "freight_mdv",
      value: 0.5928,
      fuelType: "petrol",
      vehicleType: "freight_vehicles",
      label: "MDV (<12T)",
    },
    {
      name: "freight_hdv",
      value: 0.7375,
      fuelType: "petrol",
      vehicleType: "freight_vehicles",
      label: "HDV (>12 T)",
    },
  ];

  useEffect(() => {
    if (user?.id) {
      updateCompanyVehicle();
    }
  }, [switchToLiters]);

  const updateCompanyVehicle = async () => {
    setLoader(true);
    const data = {
      company_vehile_prefrence: switchToLiters ? "liters" : "kilometers",
    };

    const formData = JSON.stringify(data);
    await updateCompanyVehiclePrefrence(formData, user?.id);
    await authenticateUser();
    setLoader(false);
  };

  const two_wheeler_options = sampleArray?.filter(
    (i) => i.vehicleType === "two_wheeler"
  );
  const three_wheeler_options = sampleArray?.filter(
    (i) => i.vehicleType === "three_wheeler"
  );
  const passenger_car_options = sampleArray?.filter(
    (i) => i.vehicleType === "passenger_car"
  );
  const freight_vehicles_options = sampleArray?.filter(
    (i) => i.vehicleType === "freight_vehicles"
  );

  useEffect(() => {
    getData();
  }, [year]);

  const getData = async () => {
    setLoader(true);
    try {
      const { data } = await getCompanyvehicleReportByYear(year);
      const formattedData = JSON.parse(
        JSON.stringify(
          data
            ?.filter((i) => i?.type === (switchToLiters ? "liters" : "kilometers"))
            ?.map((i) => ({
              ...i,
              month: `${months.find((month) => month.value == i.month)?.name}`,
              month_num: i.month,
            }))
        )
      );

      let totalEmissionsValue = formattedData
        ?.reduce((total, item) => total + Number(item?.emissions || 0), 0)
        .toFixed(3);
      handleEmissionChange(year, "emissionFreight", totalEmissionsValue);

      formattedData?.push({
        SNo: "Total",
        emissions: totalEmissionsValue,
        type: "all",
      });

      setExcelData(formattedData);
    } catch (error) {
      if (error?.response?.data?.message) {
        const errorMessages = error.response.data.message.split(", ");

        const uniqueErrorMessages = [...new Set(errorMessages)]; // Remove duplicates

        uniqueErrorMessages.forEach((errorMessage) => {
          toast.error(errorMessage.replace(/,/g, ",\n")); // Add a line break for each comma
        });
      } else {
        toast.error("An error occurred.");
      }
    }
    setLoader(false);
  };

  const onChangeHandler = (e) => {
    setTimeout(() => {
      setyear(e?.target?.value);
    }, 1000);
  };

  const addRow = () => {
    let newData = [...excelData];
    newData.splice(-1, 0, {
      month: "January",
      month_num: 1,
      type:"kilometers",
      SNo: newData.length,
      vehicleType: "",
      vehicleDetails: "",
      kmsDriven: "",
      emissions: "",
      fuelType: "",
      vehicleRegistrationNumber: "",
    });

    setExcelData(newData);
    setShowEmissions(false);
  };

  const processData = (payload) => {
    let processedData = JSON.parse(JSON.stringify(payload)) || [];

    let totalEmissionsValue = processedData
      ?.filter((i) => i?.type === (switchToLiters ? "liters" : "kilometers"))
      ?.reduce((total, item) => total + Number(item?.emissions || 0), 0)
      ?.toFixed(3);

    handleEmissionChange(year, "emissionCompanyvehicle", totalEmissionsValue);

    processedData?.push({
      SNo: "Total",
      type: "all",
      emissions: totalEmissionsValue,
    });

    return processedData;
  };

  const handleEmissionChange = (year, field, value) => {
    dispatch(setEmission({ year, field, value }));
  };

  function sortAndModifyArray(inputArray) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Sort the array based on the month
    inputArray.sort((a, b) => {
      if (a.month && b.month) {
        return months.indexOf(a.month) - months.indexOf(b.month);
      }
      return 0;
    });

    // Fill in missing months with default values
    const defaultValues = {
      vehicleDetails: "",
      vehicleType: "",
      kmsDriven: 0,
      emissions: 0,
    };
    const lastObject = inputArray.pop(); // Remove and store the last object
    const sortedArray = [];

    months.forEach((month) => {
      const foundObject = inputArray.find((obj) => obj.month === month);
      if (foundObject) {
        sortedArray.push(foundObject);
      } else {
        sortedArray.push({ month, SNo: 0, ...defaultValues });
      }
    });

    sortedArray.push(lastObject); // Add back the last object

    return sortedArray;
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "S. No.",
        accessor: "SNo",
      },
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "vehicle Registration Number",
        accessor: "vehicleRegistrationNumber",
      },
      {
        Header: "Type of vehicle",
        accessor: "vehicleType",
      },
      {
        Header: "vehicle details",
        accessor: "vehicleDetails",
      },
      {
        Header: "KMS Driven",
        accessor: "kmsDriven",
      },
      {
        Header: "Emissions(tCO₂e)",
        accessor: "emissions",
        show: showEmissions,
      },
    ],
    [showEmissions]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: excelData?.filter((i) =>
        [switchToLiters ? "liters" : "kilometers", "all"].includes(i.type)
      ),
    });

  const handleChange = (rowIndex, columnId, value) => {
    setExcelData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          const formattedMonth =
            columnId === "month"
              ? months.find((i) => i.name == value)?.value
              : months.find((i) => i.name == row?.month)?.value;
          return {
            ...old[rowIndex],
            [columnId]: value,
            month_num: formattedMonth,
          };
        }

        return row;
      })
    );
  };

  const handleVehicleTypeChange = (rowIndex, vehicleType, name) => {
    handleChange(rowIndex, name, vehicleType);
  };

  useEffect(() => {
    if (excelData?.length < 2) {
      addRow();
    }
    if (excelData?.length > 0 && user?.company_vehile_prefrence === "liters") {
      setSwitchToLiters(user?.company_vehile_prefrence === "liters");
    }
  }, [excelData, user]);

  const generateExcel = async () => {
    setisDisabled(true);
    try {
      const formData = {
        type: !switchToLiters ? "kilometers" : "liters",
        emissionsData: excelData
          ?.filter((i) => i?.month_num > 0)
          ?.map((i) => ({
            year,
            month: i?.month_num,
            SNo: i?.SNo || 0,
            vehicleDetails: String(i?.vehicleDetails),
            vehicleRegistrationNumber: String(
              i?.vehicleRegistrationNumber
            )?.toUpperCase(),
            fuelType: String(i?.fuelType || "petrol"),
            kmsDriven: Number(i?.kmsDriven) || 0,
            vehicleType: String(i?.vehicleType),
            dieselConsumption: "",
          })),
      };

      const { data } = await generateCompanyvehicle(formData);
      processData(JSON.parse(JSON.stringify(data)));
      getData();
      // setShowEmissions(true);
      toast.success("Data generated!!");
    } catch (error) {
      if (error?.response?.data?.message) {
        const errorMessages = error.response.data.message.split(", ");

        const uniqueErrorMessages = [...new Set(errorMessages)]; // Remove duplicates

        uniqueErrorMessages.forEach((errorMessage) => {
          toast.error(errorMessage.replace(/,/g, ",\n")); // Add a line break for each comma
        });
      } else {
        toast.error("An error occurred.");
      }
    }
    setisDisabled(false);
  };

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Freight Vehicles");

    worksheet.columns = [
      { header: "Month", key: "month", width: 10 },
      ,
      { header: "S. No.", key: "SNo", width: 10 },
      { header: "Type of Freight Vehicle", key: "vehicleType", width: 25 },
      { header: "Number of kms travelled", key: "kmsDriven", width: 25 },
      { header: "Emissions (tCO₂e)", key: "emissions", width: 25 },
    ];

    excelData.forEach((item) => {
      worksheet.addRow(item);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(blob, "Freight_Vehicles.xlsx");
  };

  const onSwitchChange = () => {
    setSwitchToLiters(!switchToLiters);
  };

  return (
    <div className="card-main">
      <div className="header-main">
        <h5 className="title-main pb-0">Company Vehicle</h5>
        <button className="excelbutton" onClick={onSwitchChange}>
          {switchToLiters ? "Switch to Kilometers" : "Switch to Liters"}
        </button>
      </div>
      <div className="inner-card-main">
        {switchToLiters ? (
          <>
            <SwitchToLiters />
          </>
        ) : (
          <>
            <div className="pt-3 text-center d-flex align-items-center">
              <button
                disabled={isDisabled}
                className="excelbutton"
                onClick={addRow}
              >
                Add Row
              </button>
              <button
                disabled={isDisabled}
                className="excelbutton"
                onClick={generateExcel}
              >
                Calculate Emissions
              </button>
              {/* <button className="excelbutton" onClick={downloadExcel}>Download Excel</button> */}
              <div className="">
                <Form.Select
                  aria-label="Default select example"
                  className="form-select-main"
                  onChange={onChangeHandler}
                >
                  <option value="2021" selected={year === "2021"}>
                    2021
                  </option>
                  <option value="2022" selected={year === "2022"}>
                    2022
                  </option>
                  <option value="2023" selected={year === "2023"}>
                    2023
                  </option>
                  <option value="2024" selected={year === "2024"}>
                    2024
                  </option>
                </Form.Select>
              </div>
            </div>
            <div className="mt-3">
              {loader && <Loader />}
              {!loader && (
                <table {...getTableProps()} style={{ margin: "auto" }}>
                  <thead className="p-3">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            className="table-title"
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="" {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td
                              {...cell.getCellProps()}
                              className={classNames({
                                "month-td": cell?.column?.Header === "Month",
                                "vehical-type-td":
                                  cell?.column?.Header === "Type of vehicle",
                                "vehical-details-td":
                                  cell?.column?.Header === "vehicle details",
                                "kms-driven-td":
                                  cell?.column?.Header === "KMS Driven",
                                "emissions-td":
                                  cell?.column?.Header === "Emissions (tCO₂e)",
                              })}
                            >
                              {cell.column.id === "month" ? (
                                showEmissions || row.values.SNo === "Total" ? (
                                  <input
                                    className="table-input p-2 border-none"
                                    type="text"
                                    value={cell.value || ""}
                                    readOnly
                                  />
                                ) : (
                                  <select
                                    onChange={(e) =>
                                      handleVehicleTypeChange(
                                        i,
                                        e.target.value,
                                        "month"
                                      )
                                    }
                                    className="input-width-select p-2"
                                  >
                                    {months?.map((month, index) => (
                                      <option
                                        selected={cell.value === month?.name}
                                        value={month?.name}
                                        key={index}
                                      >
                                        {month?.name}
                                      </option>
                                    ))}
                                  </select>
                                )
                              ) : cell.column.id === "vehicleType" ? (
                                showEmissions || row.values.SNo === "Total" ? (
                                  <input
                                    className="table-input p-2 border-none"
                                    type="text"
                                    value={cell.value || ""}
                                    readOnly
                                  />
                                ) : (
                                  <select
                                    className="input-width-select p-2"
                                    value={cell.value}
                                    onChange={(e) =>
                                      handleChange(
                                        i,
                                        "vehicleType",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">
                                      Select Vehicle Type
                                    </option>
                                    <option value="two_wheeler">
                                      Two Wheeler
                                    </option>
                                    <option value="three_wheeler">
                                      Three Wheeler
                                    </option>
                                    <option value="passenger_car">
                                      Passenger Car
                                    </option>
                                    <option value="freight_vehicles">
                                      Freight Vehicles
                                    </option>
                                  </select>
                                )
                              ) : cell.column.id === "vehicleDetails" ? (
                                showEmissions || row.values.SNo === "Total" ? (
                                  <input
                                    className="table-input p-2 border-none"
                                    type={
                                      cell.column.id === "kmsDriven"
                                        ? "number"
                                        : "text"
                                    }
                                    step="0.001" // Allow only three decimal places
                                    value={cell.value || ""}
                                    readOnly={
                                      row.values.SNo === "Total" ||
                                      cell.column.id === "SNo" ||
                                      cell.column.id === "emissions"
                                    }
                                    onChange={(e) =>
                                      handleChange(
                                        i,
                                        cell.column.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <select
                                    className="input-width-select p-2"
                                    value={cell.value}
                                    onChange={(e) =>
                                      handleChange(
                                        i,
                                        "vehicleDetails",
                                        e.target.value
                                      )
                                    }
                                  >
                                    {cell.row.original.vehicleType ===
                                    "two_wheeler" ? (
                                      <>
                                        <option value="">
                                          Select Vehicle Details
                                        </option>
                                        {two_wheeler_options?.map((i) => {
                                          return (
                                            <option
                                              value={i?.name}
                                              selected={cell.value === i?.name}
                                            >
                                              {i?.label}
                                            </option>
                                          );
                                        })}
                                      </>
                                    ) : cell.row.original.vehicleType ===
                                      "three_wheeler" ? (
                                      <>
                                        <option value="">
                                          Select Vehicle Details
                                        </option>
                                        {three_wheeler_options?.map((i) => {
                                          return (
                                            <option
                                              value={i?.name}
                                              selected={cell.value === i?.name}
                                            >
                                              {i?.label}
                                            </option>
                                          );
                                        })}
                                      </>
                                    ) : cell.row.original.vehicleType ===
                                      "passenger_car" ? (
                                      <>
                                        <option value="">
                                          Select Vehicle Details
                                        </option>
                                        {passenger_car_options?.map((i) => {
                                          return (
                                            <option value={i?.name}>
                                              {i?.label}
                                            </option>
                                          );
                                        })}
                                      </>
                                    ) : cell.row.original.vehicleType ===
                                      "freight_vehicles" ? (
                                      <>
                                        <option value="">
                                          Select Vehicle Details
                                        </option>
                                        {freight_vehicles_options?.map((i) => {
                                          return (
                                            <option
                                              value={i?.name}
                                              selected={cell.value === i?.name}
                                            >
                                              {i?.label}
                                            </option>
                                          );
                                        })}
                                      </>
                                    ) : null}
                                  </select>
                                )
                              ) : (
                                <input
                                  className={classNames("table-input", {
                                    "table-input d-none":
                                      (cell?.column?.id === "kmsDriven" ||
                                        cell?.column?.id ===
                                          "vehicleRegistrationNumber") &&
                                      cell?.row?.original?.SNo === "Total",
                                  })}
                                  type={
                                    cell.column.id === "kmsDriven"
                                      ? "number"
                                      : "text"
                                  }
                                  step="0.001" // Allow only three decimal places
                                  value={cell.value || ""}
                                  disabled={
                                    row.values.SNo === "Total" ||
                                    cell.column.id === "SNo" ||
                                    cell.column.id === "emissions"
                                  }
                                  onChange={(e) => {
                                    if (cell.column.id === "kmsDriven") {
                                      const inputValue = e.target.value;
                                      if (/^\d*\.?\d{0,3}$/.test(inputValue)) {
                                        handleChange(
                                          i,
                                          cell.column.id,
                                          inputValue
                                        );
                                      }
                                    } else {
                                      handleChange(
                                        i,
                                        cell.column.id,
                                        e.target.value
                                          .toUpperCase()
                                          .slice(0, 10)
                                      );
                                    }
                                  }}
                                />
                              )}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Companyvehicle;
